import { MarketingType, ObjectType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import { useCallback } from 'react';

import { getMarketingTypeObjectTypeLabel } from '../../utils';

export type formatterFunction = (marketingType: MarketingType, objectType: ObjectType, defaultLabel?: string) => string;

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export function useMarketingTypeObjectTypeFormatter(): formatterFunction {
  return useCallback((marketingType: MarketingType, objectType: ObjectType, defaultLabel) => {
    const label = getMarketingTypeObjectTypeLabel({ t: t, marketingType: marketingType, objectType: objectType });
    if (label === '') {
      return defaultLabel ? defaultLabel : '';
    }

    return label;
  }, []);
}
