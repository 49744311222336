import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { Address, MapQueryParams } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { LatLngExpression } from 'leaflet';
import { flattenDeep } from 'lodash-es';
import { useEffect, useState } from 'react';

import { Logger } from '../../utils';

type ReducedPoi = {
  id: string;
  lng: number;
  lat: number;
  name: string;
  address: Address;
  branchCodes: Array<string>;
};

interface POIResponse {
  items: Array<ReducedPoi>;
  id: string;
  name: string;
  address: Address;
  lat: number;
  lng: number;
}

interface UseMapDataResult {
  pointsOfInterestGroups: POIResponse[];
  polygon: LatLngExpression[][] | null;
  boundary: LatLngExpression[] | null;
}

export function useMapData(lat: number, lng: number, open: boolean): UseMapDataResult {
  const [pointsOfInterestGroups, setPointsOfInterestGroups] = useState<POIResponse[]>([]);
  const [polygon, setPolygon] = useState<LatLngExpression[][] | null>(null);
  const [boundary, setBoundary] = useState<LatLngExpression[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const mapQuery: MapQueryParams = { lat: lat, lng: lng };

      ApiClientProvider.getApiClient()
        .getPolygons(mapQuery)
        .then(({ data }) => {
          const allPolygonPoints: LatLngExpression[][] = data ? (data as LatLngExpression[][]) : [];
          setPolygon(allPolygonPoints);
          setBoundary(flattenDeep(allPolygonPoints));
        })
        .catch((e) => {
          Logger.error(e);
          setPolygon(null);
          setBoundary(null);
        });

      ApiClientProvider.getApiClient()
        .getPois(mapQuery)
        .then(({ data }) => {
          setPointsOfInterestGroups(data);
        })
        .catch((e) => {
          Logger.error(e);
          setPointsOfInterestGroups([]);
        });
    };

    if (open) {
      fetchData();
    }
  }, [lat, lng, open]);

  return { pointsOfInterestGroups, polygon, boundary };
}
