import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { CatalogEntity, CatalogsResponse } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React, { createContext, FC, ReactNode, useEffect, useState } from 'react';

import { Logger } from '../utils';

interface CatalogsContextType {
  estateSubTypes: Array<CatalogEntity>;
  offerTypes: Array<CatalogEntity>;
  roomsFrom: Array<CatalogEntity>;
  radii: Array<CatalogEntity>;
  loading: boolean;
}

const initialContext: CatalogsContextType = {
  estateSubTypes: [],
  offerTypes: [],
  roomsFrom: [],
  radii: [],
  loading: true,
};

export const CatalogsContext = createContext<CatalogsContextType>(initialContext);

interface CatalogsProviderProps {
  useImmobilienApi: boolean; // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION
  children: ReactNode;
}

export const CatalogsProvider: FC<CatalogsProviderProps> = ({ useImmobilienApi, children }) => {
  const [estateSubTypes, setEstateSubTypes] = useState<Array<CatalogEntity>>(initialContext.estateSubTypes);
  const [offerTypes, setOfferTypes] = useState<Array<CatalogEntity>>(initialContext.offerTypes);
  const [roomsFrom, setRoomsFrom] = useState<Array<CatalogEntity>>(initialContext.roomsFrom);
  const [radii, setRadii] = useState<Array<CatalogEntity>>(initialContext.radii);
  const [loading, setLoading] = useState<boolean>(initialContext.loading);

  const fetchCatalogs = async () => {
    if (useImmobilienApi && loading) {
      try {
        const catalogsResponse: CatalogsResponse = await ApiClientProvider.getApiClient().getCatalogs();
        setEstateSubTypes(catalogsResponse?.estateSubTypes ?? []);
        setOfferTypes(catalogsResponse?.offerTypes ?? []);
        setRoomsFrom(catalogsResponse?.roomsFrom ?? []);
        setRadii(catalogsResponse?.radii ?? []);
      } catch (e) {
        Logger.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchCatalogs();
  }, [useImmobilienApi]);

  return (
    <CatalogsContext.Provider value={{ estateSubTypes, offerTypes, roomsFrom, radii, loading }}>
      {children}
    </CatalogsContext.Provider>
  );
};
