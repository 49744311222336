// TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: Complete context
import React, { createContext, FC, ReactNode, useEffect, useState } from 'react';

interface ImmobilienApiSwitchContextType {
  useImmobilienApi: boolean;
}

const initialContext: ImmobilienApiSwitchContextType = {
  useImmobilienApi: false,
};

export const ImmobilienApiSwitchContext = createContext<ImmobilienApiSwitchContextType>(initialContext);

interface ImmobilienApiSwitchContextProps {
  initialUseImmobilienApi: boolean;
  children: ReactNode;
}

export const ImmobilienApiSwitchProvider: FC<ImmobilienApiSwitchContextProps> = ({
  initialUseImmobilienApi,
  children,
}) => {
  const [useImmobilienApi, setUseImmobilienApi] = useState<boolean>(initialContext.useImmobilienApi);

  useEffect(() => {
    setUseImmobilienApi(initialUseImmobilienApi);
  }, [initialUseImmobilienApi]);

  return (
    <ImmobilienApiSwitchContext.Provider value={{ useImmobilienApi }}>{children}</ImmobilienApiSwitchContext.Provider>
  );
};
