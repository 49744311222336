import { SxProps, Theme } from '@mui/material/styles';

export const getStyles = (theme: Theme, imgSrc?: string): Record<string, SxProps<Theme>> => ({
  imageContainer: {
    position: 'relative',
    width: 1,
    height: 1,
    zIndex: 1,
    bgcolor: theme.palette.grey[400],
  },
  loadingOverlay: {
    position: 'absolute',
    maxWidth: { xs: '100%', sm: '259px', lg: '389px' },
    width: 1,
    height: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
  },
  carousel: {
    aspectRatio: '3/2',
  },
  bookmarkButtonContainer: {
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing(3),
    right: theme.spacing(3),
    borderRadius: '50%',
    backgroundColor: theme.palette.background.paper,
    '& button': {
      width: { xs: '42px', lg: '54px' },
      height: { xs: '42px', lg: '54px' },
    },
    '& svg': {
      width: { xs: theme.spacing(5), lg: theme.spacing(6) },
      height: { xs: theme.spacing(5), lg: theme.spacing(6) },
    },
  },
  partnerLogo: {
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
    height: '40px',
    width: '40px',
    borderRadius: '6px',
    bgcolor: theme.palette.background.default,
    zIndex: 1,
    overflow: 'hidden',
    backgroundImage: `url(${imgSrc})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
});
