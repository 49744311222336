import { EstateContactMessageProps, EstateContactRequestProps } from '../../ApiClientTypes';

export const mapEstateContactRequestProps = (contactMessage: EstateContactMessageProps): EstateContactRequestProps => {
  return {
    firstName: contactMessage.firstName,
    lastName: contactMessage.lastName,
    email: contactMessage.email,
    phoneNumber: contactMessage.phone?.length > 0 ? contactMessage.phone : undefined,
    street: contactMessage.street?.length > 0 ? contactMessage.street : undefined,
    houseNumber: contactMessage.streetNumber?.length > 0 ? contactMessage.streetNumber : undefined,
    zip: contactMessage.zip?.length > 0 ? contactMessage.zip : undefined,
    town: contactMessage.city?.length > 0 ? contactMessage.city : undefined,
    request: contactMessage.message,
  };
};
