import { InputAdornment, SvgIconProps, useTheme } from '@mui/material';
import { TextField } from '@portals/core/src/components/TextField/TextField';
import { ChevronDown } from '@portals/icons';
import React, { MouseEventHandler } from 'react';

interface Props {
  label: string;
  value?: string;
  Icon: React.FC<SvgIconProps>;
  onClick: MouseEventHandler<HTMLDivElement>;
}

export const ReadonlyFilterInput: React.FunctionComponent<Props> = ({ label, value, Icon, onClick }: Props) => {
  const theme = useTheme();
  return (
    <TextField
      fullWidth
      label={label}
      variant="outlined"
      value={value}
      slotProps={{
        input: {
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: { xs: 13, lg: 16 },
                  marginRight: { xs: theme.spacing(4), lg: theme.spacing(5) },
                },
              }}
            >
              <ChevronDown />
            </InputAdornment>
          ),
        },
        htmlInput: {
          style: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            paddingLeft: 0,
          },
        },
      }}
      onClick={onClick}
    />
  );
};

ReadonlyFilterInput.displayName = 'ReadonlyFilterInput';
