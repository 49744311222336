import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';

interface MaterialUiCarouselProps {
  index: number;
  onChange: (index: number) => void;
  fullHeightHover: boolean;
  navButtonsAlwaysVisible: boolean;
  NavButton: React.FC<{ onClick: () => void; next?: boolean; prev?: boolean }>;
  sx?: React.CSSProperties;
  duration?: number;
  children: React.ReactNode;
  fullCycle?: boolean;
}

const MaterialUiCarousel: React.FC<MaterialUiCarouselProps> = ({
  index,
  onChange,
  fullHeightHover,
  navButtonsAlwaysVisible,
  NavButton,
  sx,
  duration = 300,
  children,
  fullCycle = true,
}) => {
  const [currentIndex, setCurrentIndex] = useState(index);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const handleNext = () => {
    if (fullCycle || currentIndex < React.Children.count(children) - 1) {
      const newIndex = (currentIndex + 1) % React.Children.count(children);
      setCurrentIndex(newIndex);
      onChange(newIndex);
    }
  };

  const handlePrev = () => {
    if (fullCycle || currentIndex > 0) {
      const newIndex = (currentIndex - 1 + React.Children.count(children)) % React.Children.count(children);
      setCurrentIndex(newIndex);
      onChange(newIndex);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    touchEndX.current = e.changedTouches[0].screenX;
    handleSwipe();
  };

  const handleSwipe = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      handleNext();
    }
    if (touchStartX.current - touchEndX.current < -50) {
      handlePrev();
    }
  };

  return (
    <Box
      sx={{
        ...sx,
        position: 'relative',
        '&:hover .nav-button': fullHeightHover ? { opacity: 1 } : {},
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <Box sx={{ display: 'flex', overflow: 'hidden' }}>
        {React.Children.map(children, (child) => (
          <Box
            sx={{
              minWidth: '100%',
              transform: `translateX(-${currentIndex * 100}%)`,
              transition: `transform ${duration}ms ease`,
            }}
          >
            {child}
          </Box>
        ))}
      </Box>
      {navButtonsAlwaysVisible && (
        <>
          <Box
            className="nav-button"
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              opacity: fullHeightHover ? 0 : 1,
              transition: 'opacity 0.3s ease',
            }}
          >
            <NavButton onClick={handlePrev} prev />
          </Box>
          <Box
            className="nav-button"
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              opacity: fullHeightHover ? 0 : 1,
              transition: 'opacity 0.3s ease',
            }}
          >
            <NavButton onClick={handleNext} next />
          </Box>
        </>
      )}
    </Box>
  );
};

export default MaterialUiCarousel;
