import type { SxProps, Theme } from '@portals/core/src/themes/themes';

export const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    muiLink: { width: '100%', color: theme.palette.text.primary },
    itemContainer: { py: 0, pt: { xs: theme.spacing(8), sm: 0 } },
    container: {
      p: { xs: 0, sm: theme.spacing(5), lg: '66px' },
      '& .MuiDialog-paper': {
        p: { xs: theme.spacing(5), lg: theme.spacing(6) },
        m: 0,
        maxWidth: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      '& .MuiDialog-paper .PortalsCloseButton-Button': {
        display: 'none',
      },
    },
    closeButton: {
      position: 'absolute',
      top: { xs: '10px', lg: '16px' },
      right: { xs: '10px', lg: '16px' },
      zIndex: 10,
    },
    closeIconButton: {
      border: 'none',
      '& svg': {
        width: { xs: theme.spacing(5), lg: theme.spacing(6) },
        height: { xs: theme.spacing(5), lg: theme.spacing(6) },
      },
    },
    divider: { mt: { xs: theme.spacing(5), lg: theme.spacing(6) } },
    navContainer: {
      display: 'flex',
      pt: { xs: theme.spacing(5), lg: theme.spacing(6) },
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'stretch',
    },
    navButton: {
      borderRadius: '100%',
      borderColor: theme.palette.grey['light'],
      color: theme.palette.grey['dark'],
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      width: { xs: '42px', lg: '54px' },
      height: { xs: '42px', lg: '54px' },
    },
    navPill: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[100],
    },
  };
};
