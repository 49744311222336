import React, { FC, useContext, useEffect, useState } from 'react';
import { useLocalstorageState } from 'rooks';

import { getBookmarks, STORAGE_KEY_BOOKMARKS } from '../utils';
import { ActionFavoriteButton, EstateListViewRenderProps } from './../components';

export type BookmarkState = {
  bookmarks: Array<string>;
  loadBookmarks: () => void;
  renderBookmarkButton?: EstateListViewRenderProps['renderBookmarkButton'];
};

export const BookmarkContext = React.createContext<BookmarkState>({
  bookmarks: [],
  loadBookmarks: () => null,
  renderBookmarkButton: undefined,
});

type BookmarkProviderProps = {
  children: React.ReactNode;
  renderBookmarkButton?: EstateListViewRenderProps['renderBookmarkButton'];
};

export const BookmarkProvider: FC<BookmarkProviderProps> = ({ children, renderBookmarkButton }) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [storedBookmarks] = useLocalstorageState(STORAGE_KEY_BOOKMARKS, '');

  const loadBookmarks = () => {
    setBookmarks(getBookmarks());
  };

  const defaultRenderBookmarkButton: EstateListViewRenderProps['renderBookmarkButton'] = (estateId, size) => (
    <ActionFavoriteButton id={estateId} size={size} />
  );

  const bookmarkContext: BookmarkState = {
    bookmarks,
    loadBookmarks,
    renderBookmarkButton: renderBookmarkButton || defaultRenderBookmarkButton,
  };

  useEffect(() => {
    loadBookmarks();
  }, [storedBookmarks]);

  return <BookmarkContext.Provider value={bookmarkContext}>{children}</BookmarkContext.Provider>;
};

BookmarkProvider.displayName = 'BookmarkProvider';

export const useBookmarkContext = (): BookmarkState => {
  const context = useContext(BookmarkContext);
  if (context === undefined) {
    throw new Error('useBookmarkContext must be used within a BookmarkProvider');
  }
  return context;
};
