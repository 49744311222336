export enum EstateFilterTypes {
  MAX_PRICE = 'maxPrice',
  MIN_LIVING_SPACE = 'minLivingSpace',
  MIN_NUMBER_ROOMS = 'minNumberRooms',
  MIN_PROPERTY_SIZE = 'minPropertySize',
  MIN_TOTAL_SPACE = 'minTotalSpace',
  MAX_MARKET_VALUE = 'maxMarketValue',
  MAX_RENT = 'maxRent',
  MAX_LEASE = 'maxLease',
}
