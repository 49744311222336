import { Box, SxProps, Theme } from '@mui/material';
import { Divider } from '@portals/core/src/components/Divider/Divider';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/sde/main';
import { EstateListItem as ApiEstateListItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React, { Fragment } from 'react';

import { Badges, ImageContainer, MainFacts } from '../../components';
import { getStyles } from './EstateListItem.styles';

export type EstateListItemProps = Omit<ApiEstateListItem, 'lat' | 'lng'> & {
  ref?: any;
  bookmarkButton?: React.ReactNode;
  isLastItem?: boolean;
  itemContainerSx?: SxProps<Theme>;
};
export const EstateListItem: React.FC<EstateListItemProps> = React.forwardRef(
  (
    {
      id,
      title,
      subtitle,
      images,
      price,
      eyeCatcher,
      mainFacts,
      partnerLogo,
      bookmarkButton,
      isLastItem,
      itemContainerSx,
    },
    ref
  ) => {
    const styles = getStyles(theme);
    const combinedSx = Object.assign({}, styles.itemContainer, itemContainerSx) as SxProps<Theme>;

    return (
      <Fragment key={id}>
        <Box data-id="estate-list-item" ref={ref} className="estate-list-item-row" sx={combinedSx}>
          <Box sx={styles.imageContainer}>
            <ImageContainer
              title={title}
              imageList={images}
              partnerLogo={partnerLogo}
              bookmarkButton={bookmarkButton}
            />
          </Box>
          <Box sx={styles.infoWrapper}>
            <Box className="estate-list-item-info-container" sx={styles.infoContainer}>
              <Typography className="estate-item-header" variant="h6" component="h6">
                {subtitle}
              </Typography>
              <Box className="estate-list-item-info-content" sx={styles.infoContent}>
                <Typography className="estate-item-header-subtitle" variant="h3" component="h3" sx={styles.title}>
                  {title}
                </Typography>

                <Badges badgeItems={eyeCatcher} outerBoxSx={styles.eyeCatcher} />
              </Box>
            </Box>
            <Box sx={styles.footer}>
              <Box data-testid="estateListItemFacts">
                <MainFacts list={mainFacts} outerBoxSx={styles.mainFacts} />
              </Box>
              <Typography className="estate-list-price" variant="h2" component="span">
                {price}
              </Typography>
            </Box>
          </Box>
        </Box>
        {!isLastItem && <Divider />}
      </Fragment>
    );
  }
);

EstateListItem.displayName = 'EstateListItem';
