import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Logger } from '../../utils';

interface Props {
  width: number;
  height: number;
}

interface Style {
  aspectRatio?: {
    xs: string;
    md: string;
    lg: string;
  };

  width?: {
    xs: number | string;
    lg: number | string;
  };
  height?: {
    xs: number | string;
    lg: number | string;
  };
}

interface Result<T> {
  style: Style;
  ref?: RefObject<T>;
}

/**
 *
 * @param xsWidth: width on xs, ex 4
 * @param xsHeight: height on xs, ex 3
 * @param lgWidth: width on lg, ex 16
 * @param lgHeight: height on lg, ex 9
 */
export function useAspectRatio<T extends HTMLElement>(
  { width: xsWidth, height: xsHeight }: Props,
  { width: mdWidth, height: mdHeight }: Props = { width: xsWidth, height: xsHeight },
  { width: lgWidth, height: lgHeight }: Props = { width: xsWidth, height: xsHeight }
): Result<T> {
  const ref = useRef<T>();
  const [style, setStyle] = useState<Style>({
    aspectRatio: {
      xs: `${xsWidth}/${xsHeight}`,
      md: `${mdWidth}/${mdHeight}`,
      lg: `${lgWidth}/${lgHeight}`,
    },
  });

  useEffect(() => {
    if (!CSS?.supports || !CSS?.supports('aspect-ratio', `${xsWidth}/${xsHeight}`)) {
      return;
    }

    setStyle({
      aspectRatio: {
        xs: `${xsWidth}/${xsHeight}`,
        md: `${mdWidth}/${mdHeight}`,
        lg: `${lgWidth}/${lgHeight}`,
      },
    });
  }, [xsWidth, xsHeight, lgWidth, lgHeight, mdWidth, mdHeight]);

  (typeof window !== 'undefined' ? useLayoutEffect : useEffect)(() => {
    const target = ref.current;

    if (CSS?.supports && CSS?.supports('aspect-ratio', `${xsWidth}/${xsHeight}`)) {
      return;
    }

    if (!target) {
      return;
    }

    const listener = () => {
      if (!target || !target.getBoundingClientRect) {
        Logger.error('Lost target in listener', { target });

        return;
      }

      const objectWidth = target.getBoundingClientRect().width;
      const objectXsHeight = (objectWidth / xsWidth) * xsHeight;
      const objectMdHeight = (objectWidth / mdWidth) * mdHeight;
      const objectLgHeight = (objectWidth / lgWidth) * lgHeight;

      setStyle((currentStyle) => {
        if (currentStyle.height?.xs === objectXsHeight && currentStyle.height?.lg === objectLgHeight) {
          return currentStyle;
        }

        return {
          width: {
            xs: '100%',
            md: '100%',
            lg: '100%',
          },
          height: {
            xs: objectXsHeight,
            md: objectMdHeight,
            lg: objectLgHeight,
          },
        };
      });
    };

    listener();

    if (typeof ResizeObserver !== 'undefined') {
      const resizeObserver = new ResizeObserver(listener);
      resizeObserver.observe(target);

      return () => {
        resizeObserver.unobserve(target);
      };
    }
  }, [lgHeight, lgWidth, xsHeight, xsWidth, mdWidth, mdHeight]);

  return {
    ref,
    style,
  };
}
