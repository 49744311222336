import { Box, Grid, useTheme } from '@mui/material';
import React, { CSSProperties } from 'react';

import { EstateImage } from '../../../../EstateImage/EstateImage';
import { getStyles } from '../ImageSection.styles';
import { ImageObject } from '../ImageSection.types';
import { AdditionalImageLabel } from './AdditionalImageLabel/AdditionalImageLabel';

interface PreviewImageProps {
  imageObject: ImageObject;
  additionalImageCount?: number;
  onClick: React.MouseEventHandler;
}

export const PreviewImage: React.FunctionComponent<PreviewImageProps> = ({
  imageObject,
  additionalImageCount = 0,
  onClick,
}: PreviewImageProps) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Grid item xs={6}>
      <Box sx={styles.estateImageContainer} onClick={onClick} className="image-container">
        {imageObject && <EstateImage imageObject={imageObject} imageStyle={styles.estateImage as CSSProperties} />}
        {additionalImageCount > 0 && <AdditionalImageLabel additionalImageCount={additionalImageCount} />}
      </Box>
    </Grid>
  );
};
PreviewImage.displayName = 'PreviewImage';
