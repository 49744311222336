import { Box, Divider, Typography, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import type { Theme } from '@portals/core/src/themes/themes';
import { Bookmark } from '@portals/icons';
import { EstateSearchProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React, { ReactElement, useContext } from 'react';

import { CatalogsContext } from '../../../context';
import { useEstateListHeadingFormatter } from '../../../hooks';

interface HeaderProps {
  searchParams: EstateSearchProps;
  totalItems: number;
  buttonContent?: ReactElement;
}

type SaveSearchDisabled = {
  saveSearchButtonLabel?: string;
  onClickSaveSearch?: () => void;
  isSaveSearchButtonDisabled: true;
};

type SaveSearchEnabled = {
  saveSearchButtonLabel: string;
  onClickSaveSearch: () => void;
  isSaveSearchButtonDisabled: false;
};

type SaveSearchProps = SaveSearchDisabled | SaveSearchEnabled;

type Props = HeaderProps & SaveSearchProps;

export const Headline = ({
  searchParams,
  totalItems,
  onClickSaveSearch,
  saveSearchButtonLabel,
  isSaveSearchButtonDisabled,
  buttonContent,
}: Props): React.ReactElement => {
  const theme = useTheme<Theme>();
  const { estateSubTypes: catalogsEstateSubTypes } = useContext(CatalogsContext);
  const headingFormatter = useEstateListHeadingFormatter();
  return (
    <Box
      className="estate-list-headline-container"
      display="flex"
      justifyContent="space-between"
      sx={{ flexDirection: { xs: 'column', lg: 'row' }, width: '100%' }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', order: { xs: 1, lg: 2 } }} whiteSpace="nowrap">
        {!isSaveSearchButtonDisabled && (
          <Button
            color="inherit"
            size="small"
            endIcon={<Bookmark />}
            sx={{
              color: theme.palette.primary.main,
              py: theme.spacing(5),
              fontSize: 14,
            }}
            onClick={onClickSaveSearch}
          >
            {saveSearchButtonLabel}
          </Button>
        )}
      </Box>
      <Box
        mb={5}
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          mt: { xs: 0, sm: 3, md: 0 },
          mb: 0,
          order: { xs: 2, lg: 1 },
        }}
      >
        <Box
          className="estate-list-headline"
          pl={{
            xs: 0,
            text: 6,
          }}
          pr={2}
          sx={{ display: 'flex', alignItems: { lg: 'center' } }}
        >
          <Typography
            variant="h2"
            sx={{
              mb: { xs: 6, sm: 0, md: 0, lg: 6 },
              mt: { xs: 2, sm: 0, md: 0 },
              fontSize: { xs: 18, lg: 30 },
            }}
          >
            {headingFormatter(searchParams, totalItems, catalogsEstateSubTypes ?? null)}
          </Typography>
        </Box>
        {buttonContent}
      </Box>
      <Divider sx={{ display: { sm: 'block', text: 'none' }, order: 3, my: { xs: 5, sm: 6 } }} />
    </Box>
  );
};

Headline.displayName = 'Headline';
