import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@portals/icons';
import i18next from 'i18next';
import React from 'react';

interface CarouselIconProps {
  onClick: () => void;
  next?: boolean;
  prev?: boolean;
  currentIndex?: number;
  totalItems?: number;
  style?: any;
  theme?: any;
  sx?: any;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const CarouselIcon: React.FC<CarouselIconProps> = ({
  onClick,
  next,
  prev,
  currentIndex,
  totalItems,
  style,
  theme,
  sx,
}) => {
  if ((prev && currentIndex === 0) || (next && currentIndex === totalItems - 1)) {
    return null;
  }

  return (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      style={
        style
          ? style
          : {
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              borderRadius: '50%',
              backgroundColor: theme?.palette?.background?.default || '#FFFFFF',
              color: '#000000',
              border: 'none',
              opacity: 0.6,
              transition: 'opacity 0.3s ease',
              left: prev ? '10px' : 'auto',
              right: next ? '10px' : 'auto',
              pointerEvents: 'auto',
            }
      }
      aria-label={next ? t('gallery.nextItem') : t('gallery.previousItem')}
      className="nav-button"
      sx={sx}
    >
      {next && <ChevronRight />}
      {prev && <ChevronLeft />}
    </IconButton>
  );
};

CarouselIcon.displayName = 'CarouselIcon';
