import { Box, Grid, SxProps, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { ChevronDown, Home } from '@portals/icons';
import { Form, Formik } from 'formik';
import i18next from 'i18next';
import { assign, get, isEqual, keys, pick } from 'lodash-es';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { object } from 'yup';

import { ButtonGroupSelectTextField, HitButton } from '../../../components';
import { getAllMarketingTypes, getPerimeterOptions } from '../../../config';
import { CatalogsContext } from '../../../context';
import { useEstateSearchLocalStorage } from '../../../hooks';
import {
  fieldHasValue,
  getEstateTypeIdOptions,
  getMarketingTypeEstateTypeIdLabel,
  prefillDefaultSearchValues,
  removeEmptyParams,
  removeNotWhitelistedParamsByEstateTypeId,
} from '../../../utils';
import { stylesFn } from '../EstateSearch.styles';
import type { EstateSearchMainFormProps, EstateSearchProps } from '../EstateSearch.types';
import { getBasicValidationSchema, getDefaultValues } from '../EstateSearch.utils';
import { EstateSearchSkeleton } from '../EstateSearchSkeleton';
import { CustomSelect } from '../EstateSelect/EstateSelect';
import { ZipCityEstateIdAutocomplete } from '../ZipCityEstateIdAutocomplete/ZipCityEstateIdAutocomplete';
import { EstateSearchFilterView } from './EstateSearchFilterView/EstateSearchFilterView';

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateSearch = ({
  isSkeletonEnabled = false,
  perimeter,
  updateEstateSearchHandler,
}: EstateSearchProps): React.ReactElement => {
  const theme = useTheme();
  const styles = stylesFn(theme);
  const { lastSearchQuery, updateLastSearchQuery } = useEstateSearchLocalStorage();
  const defaultValues = getDefaultValues(t, perimeter);
  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState<EstateSearchMainFormProps>(defaultValues);
  const [isReadyToRender, setIsReadyToRender] = useState(false);
  const [isExtendedFieldsButtonClicked, setIsExtendedFieldsButtonClicked] = useState(false);
  const {
    loading: catalogsLoading,
    radii: catalogsRadii,
    estateSubTypes: catalogsEstateSubTypes,
  } = useContext(CatalogsContext);

  const setInititalValuesfromLocalStorage = () => {
    const selectedCategory = {
      lastClickedMarketingTypeValue: get(lastSearchQuery, 'marketingType', defaultValues.marketingType),
      marketingTypeObjectTypeTextfieldValue: getMarketingTypeEstateTypeIdLabel({
        estateSubTypes: catalogsEstateSubTypes,
        marketingType: get(lastSearchQuery, 'marketingType', defaultValues.marketingType),
        estateTypeId: get(lastSearchQuery, 'estateTypeId', defaultValues.estateTypeId),
      }),
    };

    const allowedSearchFormProps = pick(lastSearchQuery, keys(defaultValues));
    const initialSearchFormProps = { ...allowedSearchFormProps, ...selectedCategory } as EstateSearchMainFormProps;
    setInitialValues(initialSearchFormProps);
  };

  useEffect(() => {
    if (catalogsEstateSubTypes?.length > 0) {
      if (lastSearchQuery) {
        setInititalValuesfromLocalStorage();
      }
      setIsReadyToRender(true);
    }
  }, [catalogsEstateSubTypes]);

  const showExtendedFieldsOnClick = useCallback(() => {
    setIsExtendedFieldsButtonClicked(true);
  }, []);

  const normalizePerimeterValue = (value) => {
    return value === Number.MAX_VALUE ? null : value;
  };

  const FormikOnChange = ({ values, callback }) => {
    useEffect(() => {
      callback(values);
    }, [values]);

    return null;
  };

  const persistQuery = useCallback(
    (values: EstateSearchMainFormProps) => {
      const defaultSearchValues = prefillDefaultSearchValues(values);
      const whitelistedApiParams = removeNotWhitelistedParamsByEstateTypeId(
        catalogsEstateSubTypes,
        defaultSearchValues
      );
      const queryParamsWithoutEmpty = removeEmptyParams(whitelistedApiParams);

      if (!isEqual(queryParamsWithoutEmpty, lastSearchQuery)) {
        updateLastSearchQuery(queryParamsWithoutEmpty);
      }
    },
    [lastSearchQuery, updateLastSearchQuery]
  );

  const handleFormSubmit = useCallback(
    async (values) => {
      const defaultSearchValues = prefillDefaultSearchValues(values);
      const whitelistedApiParams = removeNotWhitelistedParamsByEstateTypeId(
        catalogsEstateSubTypes,
        defaultSearchValues
      );
      const queryParamsWithoutEmpty = removeEmptyParams(whitelistedApiParams);

      updateEstateSearchHandler(queryParamsWithoutEmpty);
    },
    [updateEstateSearchHandler]
  );

  return (
    <Box data-id="estate-search" className="search-form" sx={styles.outerBox} translate="no">
      {(!isReadyToRender || catalogsLoading) && isSkeletonEnabled && <EstateSearchSkeleton />}
      {isReadyToRender && !catalogsLoading && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={object().shape(validationSchema)}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldTouched, setFieldValue, isValid }) => {
            const handlePerimeterChange = (value) => {
              setFieldTouched('perimeter', true);
              setFieldValue('perimeter', normalizePerimeterValue(value), true);
            };

            const handleButtonGroupClose = () => {
              if (values.marketingType !== values.lastClickedMarketingTypeValue) {
                setFieldTouched('marketingType', true);
                setFieldValue('marketingType', values.lastClickedMarketingTypeValue, true);
              }
            };

            const handleButtonGroupButtonClick = (value) => {
              setFieldTouched('marketingType', true);
              setFieldValue('marketingType', value, true);
              setFieldValue(
                'marketingTypeObjectTypeTextfieldValue',
                getMarketingTypeEstateTypeIdLabel({
                  estateSubTypes: catalogsEstateSubTypes,
                  marketingType: value,
                  estateTypeId: values.estateTypeId,
                }),
                false
              );
              return false;
            };

            const handleButtonGroupSelectClick = (selectedMenuItem) => {
              setFieldTouched('lastClickedMarketingTypeValue', true);
              setFieldValue('lastClickedMarketingTypeValue', values.marketingType, true);
              setFieldTouched('estateTypeId', true);
              setFieldValue('estateTypeId', selectedMenuItem, true);
              setFieldValue(
                'marketingTypeObjectTypeTextfieldValue',
                getMarketingTypeEstateTypeIdLabel({
                  estateSubTypes: catalogsEstateSubTypes,
                  marketingType: values.marketingType,
                  estateTypeId: selectedMenuItem,
                }),
                false
              );
              return true;
            };

            const updateValidationSchema = (subfieldValidationSchema) => {
              const currentValidationSchema = assign({}, getBasicValidationSchema(t), subfieldValidationSchema);
              if (!isEqual(Object.keys(currentValidationSchema), Object.keys(validationSchema))) {
                setValidationSchema(currentValidationSchema);
              }
            };

            return (
              <Form>
                <FormikOnChange values={values} callback={persistQuery} />
                <Box>
                  <Grid container mb={4}>
                    <Grid item xs={12} md={7}>
                      <Box sx={styles.zipCityAutocompleteBox}>
                        <ZipCityEstateIdAutocomplete fieldName="zipCityEstateId" />
                        <Box sx={styles.customSelectBox}>
                          <CustomSelect
                            testId="perimeter"
                            initialValue={values.perimeter}
                            items={getPerimeterOptions(t, catalogsRadii)}
                            onChange={handlePerimeterChange}
                            formatValue={(value) => t('estateSearch.perimeter', { value: value })}
                            selectProps={{ variant: 'outlined', className: 'perimeter-select' }}
                            selectStyles={styles.customSelectStyles}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Box sx={styles.buttonGroupSelectBox}>
                        <ButtonGroupSelectTextField
                          InputAdornmentIcon={Home}
                          label={t('what')}
                          inputValue={values.marketingTypeObjectTypeTextfieldValue}
                          buttonOptions={getAllMarketingTypes(t)}
                          buttonValue={values.marketingType}
                          selectOptions={getEstateTypeIdOptions(catalogsEstateSubTypes, values.marketingType)}
                          selectValue={values.estateTypeId}
                          highlightSelectedOption={values.marketingType === values.lastClickedMarketingTypeValue}
                          onCloseHandler={handleButtonGroupClose}
                          onButtonClick={handleButtonGroupButtonClick}
                          onSelectClick={handleButtonGroupSelectClick}
                          menuMaxHeight={310}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container sx={styles.outerGrid}>
                    <Grid
                      item
                      xs={12}
                      sx={
                        {
                          ...styles.extendFieldsButtonGridDefault,
                          ...(isExtendedFieldsButtonClicked && styles.extendFieldsButtonGridHidden),
                        } as SxProps
                      }
                    >
                      <Button
                        className="extended-options-button"
                        sx={styles.extendFieldsButton}
                        variant="inverted"
                        endIcon={<ChevronDown />}
                        onClick={showExtendedFieldsOnClick}
                      >
                        {t('estateSearch.moreOptions')}
                      </Button>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={8}
                      lg={8}
                      spacing={5}
                      sx={
                        {
                          ...styles.extendedFieldsGridDefault,
                          ...styles.extendedFieldsGridDefaultInput,
                          ...(isExtendedFieldsButtonClicked && styles.extendedFieldsGridExtended),
                        } as SxProps
                      }
                    >
                      <EstateSearchFilterView
                        setValidationSchema={updateValidationSchema}
                        marketingType={values.lastClickedMarketingTypeValue}
                        estateTypeId={values.estateTypeId}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} sx={styles.hitButtonGrid}>
                      <HitButton disabled={!fieldHasValue(values.zipCityEstateId) || !isValid} />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

EstateSearch.displayName = 'EstateSearch';
