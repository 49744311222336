import React, { useCallback, useMemo, useState } from 'react';

import { CarouselIcon } from '../MaterialUiCarousel/CarouselIcon';
import MaterialUiCarousel from '../MaterialUiCarousel/MaterialUiCarousel';

interface CarouselProps {
  children: React.ReactNode[];
  preloadCount?: number;
  sx?: any;
}

export const Carousel: React.FC<CarouselProps> = ({ children, preloadCount = 3, ...props }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [childListLimit, setChildListLimit] = useState(preloadCount);

  const handleCarouselChange = useCallback(
    (now: number) => {
      if (!childListLimit) {
        return;
      }
      const preloadLimit = now + 2;
      if (now > currentIndex) {
        setTimeout(() => {
          setCurrentIndex(now);
          if (preloadLimit > childListLimit) {
            setChildListLimit(preloadLimit);
          }
        }, 300);
      } else {
        setCurrentIndex(now); // Update currentIndex when navigating back
      }
    },
    [currentIndex, childListLimit]
  );

  const filteredChildren = useMemo(() => {
    if (!childListLimit) {
      return children;
    }
    return children.filter((child, key) => key < childListLimit);
  }, [children, childListLimit]);

  return (
    <MaterialUiCarousel
      index={currentIndex}
      onChange={handleCarouselChange}
      fullCycle={false}
      duration={200}
      fullHeightHover={true}
      navButtonsAlwaysVisible={true}
      NavButton={(props) => <CarouselIcon {...props} currentIndex={currentIndex} totalItems={children.length} />}
      {...props}
    >
      {filteredChildren}
    </MaterialUiCarousel>
  );
};

Carousel.displayName = 'Carousel';
