import {
  CatalogEntity,
  EstateSearchProps,
  MarketingType,
  ObjectType,
} from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import { useCallback } from 'react';

import { useLocationFormatter } from '../../hooks';
import { getMarketingTypeEstateTypeIdLabel } from '../../utils';

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export function useEstateListHeadingFormatter() {
  const locationFormatter = useLocationFormatter();

  const determineObjectLabel = (objectType: ObjectType, totalItems: number) => {
    switch (objectType) {
      case ObjectType.HOUSE:
        return t(`estateListHeading.objectTypes.house`, { count: totalItems });
      case ObjectType.FLAT:
        return t(`estateListHeading.objectTypes.flat`, { count: totalItems });
      case ObjectType.PROPERTY:
        return t(`estateListHeading.objectTypes.property`, { count: totalItems });
      case ObjectType.FORECLOSURE:
        return t(`estateListHeading.objectTypes.foreclosure`, { count: totalItems });
      case ObjectType.INTEREST:
        return t(`estateListHeading.objectTypes.interest`, { count: totalItems });
      case ObjectType.BUSINESS:
        return t(`estateListHeading.objectTypes.business`, { count: totalItems });
      default:
        return t(`estateListHeading.objectTypes.fallback`, { count: totalItems });
    }
  };

  const determineObjectLabelByEstateTypeId = (
    catalogsEstateSubTypes: Array<CatalogEntity>,
    estateTypeId: number,
    totalItems: number
  ) => {
    const estateTypeLabel = getMarketingTypeEstateTypeIdLabel({
      estateSubTypes: catalogsEstateSubTypes,
      estateTypeId: +estateTypeId,
    });
    return `${t(`estateListHeading.estateTypeId`, { count: totalItems })} ${estateTypeLabel}`;
  };

  const determineMarketingLabel = (marketingType: MarketingType): string => {
    switch (marketingType) {
      case MarketingType.BUY:
        return t(`estateListHeading.marketingTypes.buy`);
      case MarketingType.RENT:
        return t(`estateListHeading.marketingTypes.rent`);
      default:
        return '';
    }
  };

  return useCallback(
    (searchParams: EstateSearchProps, totalItems: number, catalogsEstateSubTypes?: Array<CatalogEntity>): string => {
      const parts = [totalItems.toString()];

      const objectLabel =
        searchParams.estateTypeId && catalogsEstateSubTypes?.length > 0
          ? determineObjectLabelByEstateTypeId(catalogsEstateSubTypes, searchParams.estateTypeId, totalItems)
          : determineObjectLabel(searchParams.objectType, totalItems);
      parts.push(objectLabel);

      const marketingLabel = determineMarketingLabel(searchParams.marketingType);
      if (marketingLabel) {
        parts.push(marketingLabel);
      }

      if (searchParams.zipCityEstateId) {
        parts.push(`in ${locationFormatter(searchParams.zipCityEstateId)}`);

        if (searchParams.perimeter > 0) {
          parts.push(`+ ${searchParams.perimeter} km`);
        }
      }

      return parts.join(' ');
    },
    []
  );
}
