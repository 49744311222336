import { MarketingType, ObjectType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { number, NumberSchema } from 'yup';

import { Criteria, EstateFilterTypes, FilterConfigFormat } from '../config';

export const FLAT_IDS = [-11, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 134, -12, 120];
export const HOUSE_IDS = [
  -7, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 135,
];
export const PROPERTY_IDS = [
  -5, 34, 35, 36, 37, 38, 39, 40, 41, 42, -8, 80, 81, 82, 83, 84, 85, 86, 87, 88, 90, 91, 92,
];
export const OFFICE_IDS = [-1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const COMMERCE_IDS = [-2, 11, 12, 13, 14, 15, 16, 17, 18, 19];
export const CATERING_IDS = [-4, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33];
export const HALL_IDS = [-6, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 136];
export const OTHERS_IDS = [
  -3, 20, 21, 22, 89, 93, -9, 94, 95, 96, 97, 98, 99, 100, 101, 102, 137, -10, 103, 104, 105, 106,
];
export const INTEREST_IDS = [-13, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133];

export const getObjectTypeFromSubTypeId = (estateSubTypeId: number): ObjectType => {
  if (FLAT_IDS.includes(estateSubTypeId)) {
    return ObjectType.FLAT;
  }
  if (HOUSE_IDS.includes(estateSubTypeId)) {
    return ObjectType.HOUSE;
  }
  if (PROPERTY_IDS.includes(estateSubTypeId)) {
    return ObjectType.PROPERTY;
  }
  if (OFFICE_IDS.includes(estateSubTypeId)) {
    return ObjectType.OFFICE;
  }
  if (COMMERCE_IDS.includes(estateSubTypeId)) {
    return ObjectType.COMMERCE;
  }
  if (CATERING_IDS.includes(estateSubTypeId)) {
    return ObjectType.CATERING;
  }
  if (HALL_IDS.includes(estateSubTypeId)) {
    return ObjectType.HALL;
  }
  if (INTEREST_IDS.includes(estateSubTypeId)) {
    return ObjectType.INTEREST;
  }
  return ObjectType.OTHERS;
};

const getFilterFieldMaxPrice = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MAX_PRICE } : Criteria.PRICE;

const getFilterFieldMaxRent = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MAX_RENT } : Criteria.RENT;

const getFilterFieldMaxLease = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MAX_LEASE } : Criteria.LEASE;

const getFilterFieldMinLivingSpace = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MIN_LIVING_SPACE } : Criteria.LIVING_SPACE;

const getFilterFieldMinNumberRooms = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MIN_NUMBER_ROOMS } : Criteria.ROOMS;

const getFilterFieldMinPropertySize = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType
    ? { type: EstateFilterTypes.MIN_PROPERTY_SIZE }
    : Criteria.PROPERTY_SIZE;

const getFilterFieldMinTotalSpace = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MIN_TOTAL_SPACE } : Criteria.TOTAL_SPACE;

const priceValidationSchema = () => number().integer().min(0).max(999999999).nullable();
const livingSpaceValidationSchema = () => number().integer().min(0).max(10000).nullable();
const numberRoomsValidationSchema = () => number().min(0).max(10000).nullable();
const propertySizeValidationSchema = () => number().integer().min(0).max(10000).nullable();
const totalSpaceValidationSchema = () => number().integer().min(0).max(10000).nullable();

export type FilterConfigEstateTypeFilter = Array<{
  marketingType: MarketingType;
  estateTypeId: number;
  fields: Array<{ type: EstateFilterTypes }>;
  validationSchema?: Record<string, NumberSchema>;
}>;

export type FilterConfigCriteria = Array<{
  marketingType: MarketingType;
  estateTypeId: number;
  fields: Array<Criteria>;
}>;

export type FilterConfig = Array<{
  marketingType: MarketingType;
  estateTypeId: number;
  fields: Array<{ type: EstateFilterTypes } | Criteria>;
  validationSchema?: Record<string, NumberSchema>;
}>;

export const getFilterConfig = (format: FilterConfigFormat): FilterConfig => {
  return [
    {
      marketingType: MarketingType.BUY,
      estateTypeId: FLAT_IDS[0] * -1,
      fields: [
        getFilterFieldMaxPrice(format),
        getFilterFieldMinLivingSpace(format),
        getFilterFieldMinNumberRooms(format),
      ],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minLivingSpace: livingSpaceValidationSchema(),
              minNumberRooms: numberRoomsValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.BUY,
      estateTypeId: HOUSE_IDS[0] * -1,
      fields: [
        getFilterFieldMaxPrice(format),
        getFilterFieldMinLivingSpace(format),
        getFilterFieldMinNumberRooms(format),
      ],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minLivingSpace: livingSpaceValidationSchema(),
              minNumberRooms: numberRoomsValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.BUY,
      estateTypeId: PROPERTY_IDS[0] * -1,
      fields: [getFilterFieldMaxPrice(format), getFilterFieldMinPropertySize(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minPropertySize: propertySizeValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.BUY,
      estateTypeId: OFFICE_IDS[0] * -1,
      fields: [getFilterFieldMaxPrice(format), getFilterFieldMinTotalSpace(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minTotalSpace: totalSpaceValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.BUY,
      estateTypeId: COMMERCE_IDS[0] * -1,
      fields: [getFilterFieldMaxPrice(format), getFilterFieldMinTotalSpace(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minTotalSpace: totalSpaceValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.BUY,
      estateTypeId: CATERING_IDS[0] * -1,
      fields: [getFilterFieldMaxPrice(format), getFilterFieldMinTotalSpace(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minTotalSpace: totalSpaceValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.BUY,
      estateTypeId: HALL_IDS[0] * -1,
      fields: [getFilterFieldMaxPrice(format), getFilterFieldMinTotalSpace(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minTotalSpace: totalSpaceValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.BUY,
      estateTypeId: OTHERS_IDS[0] * -1,
      fields: [getFilterFieldMaxPrice(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.BUY,
      estateTypeId: INTEREST_IDS[0] * -1,
      fields: [getFilterFieldMaxPrice(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.RENT,
      estateTypeId: FLAT_IDS[0] * -1,
      fields: [
        getFilterFieldMaxRent(format),
        getFilterFieldMinLivingSpace(format),
        getFilterFieldMinNumberRooms(format),
      ],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minLivingSpace: livingSpaceValidationSchema(),
              minNumberRooms: numberRoomsValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.RENT,
      estateTypeId: HOUSE_IDS[0] * -1,
      fields: [
        getFilterFieldMaxRent(format),
        getFilterFieldMinLivingSpace(format),
        getFilterFieldMinNumberRooms(format),
      ],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minLivingSpace: livingSpaceValidationSchema(),
              minNumberRooms: numberRoomsValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.RENT,
      estateTypeId: PROPERTY_IDS[0] * -1,
      fields: [getFilterFieldMaxLease(format), getFilterFieldMinPropertySize(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minPropertySize: propertySizeValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.BUY,
      estateTypeId: OFFICE_IDS[0] * -1,
      fields: [getFilterFieldMaxRent(format), getFilterFieldMinTotalSpace(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minTotalSpace: totalSpaceValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.RENT,
      estateTypeId: COMMERCE_IDS[0] * -1,
      fields: [getFilterFieldMaxRent(format), getFilterFieldMinTotalSpace(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minTotalSpace: totalSpaceValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.RENT,
      estateTypeId: CATERING_IDS[0] * -1,
      fields: [getFilterFieldMaxRent(format), getFilterFieldMinTotalSpace(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minTotalSpace: totalSpaceValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.RENT,
      estateTypeId: HALL_IDS[0] * -1,
      fields: [getFilterFieldMaxRent(format), getFilterFieldMinTotalSpace(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
              minTotalSpace: totalSpaceValidationSchema(),
            }
          : undefined,
    },
    {
      marketingType: MarketingType.RENT,
      estateTypeId: OTHERS_IDS[0] * -1,
      fields: [getFilterFieldMaxRent(format)],
      validationSchema:
        format === FilterConfigFormat.EstateFilterType
          ? {
              maxPrice: priceValidationSchema(),
            }
          : undefined,
    },
  ];
};
