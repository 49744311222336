import { CatalogEntity } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { TFunction } from 'i18next';
import { find, get } from 'lodash-es';

import { OptionProps } from '../estateSearch';

export const getPerimeterOptions = (t: TFunction, catalogsRadii?: Array<CatalogEntity>): OptionProps[] => {
  if (catalogsRadii?.length > 0) {
    return catalogsRadii
      .filter((item) => item.id > -1)
      .map((item) => ({
        value: item.id,
        label: item.code,
      }));
  }
  return [
    {
      value: 1,
      label: t('estateSearch.perimeter', { value: 1 }),
    },
    {
      value: 2,
      label: t('estateSearch.perimeter', { value: 2 }),
    },
    {
      value: 5,
      label: t('estateSearch.perimeter', { value: 5 }),
    },
    {
      value: 10,
      label: t('estateSearch.perimeter', { value: 10 }),
    },
    {
      value: 15,
      label: t('estateSearch.perimeter', { value: 15 }),
    },
    {
      value: 25,
      label: t('estateSearch.perimeter', { value: 25 }),
    },
    {
      value: 50,
      label: t('estateSearch.perimeter', { value: 50 }),
    },
  ];
};

export const getPerimeterLabelFromOptionsByValue = (
  t: TFunction,
  value: number,
  catalogsRadii?: Array<CatalogEntity>
): boolean | string => {
  const selectedOption = find(getPerimeterOptions(t, catalogsRadii), (option) => {
    return option.value == value;
  });
  return get(selectedOption, 'label', '');
};
