import { Box, SxProps } from '@mui/material';
import { EstateEyecatcher } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React from 'react';

import PropertyPill from './PropertyPill/PropertyPill';

export interface BadgesProps {
  badgeItems: Array<EstateEyecatcher>;
  outerBoxSx?: SxProps;
}

export const Badges: React.FC<BadgesProps> = ({ badgeItems, outerBoxSx = {} }: BadgesProps) => {
  if (!badgeItems?.length) {
    return null;
  }

  const renderBadge = (badgeItem: EstateEyecatcher, key) => (
    <PropertyPill
      key={`${key}-badge-${badgeItem.type}`}
      size="small"
      label={badgeItem.label}
      isHighlighted={badgeItem.type === 'highlighted'}
    />
  );

  if (Object.keys(outerBoxSx).length > 0) {
    return <Box sx={outerBoxSx}>{badgeItems.map(renderBadge)}</Box>;
  }

  return <>{badgeItems.map(renderBadge)}</>;
};

Badges.displayName = 'Badges';
