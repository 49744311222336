import { MarketingType, ObjectType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { indexOf, size, trim } from 'lodash-es';

import type { FilterValuesProps } from '../../../../../types';

export const getNumberOfActiveFilters = (filterValues: FilterValuesProps): number => {
  let count = 0;
  if (filterValues.zipCityEstateId !== null) {
    count = count + 1;
  }

  if (filterValues.marketingType !== null && filterValues.objectType !== null) {
    count = count + 1;
  }

  if (filterValues.marketingType === MarketingType.BUY && filterValues.objectType == ObjectType.FORECLOSURE) {
    if (filterValues.minMarketValue !== null || filterValues.maxMarketValue !== null) {
      count = count + 1;
    }
  }
  if (
    filterValues.marketingType === MarketingType.RENT &&
    indexOf([ObjectType.FLAT, ObjectType.HOUSE, ObjectType.BUSINESS], filterValues.objectType) > -1
  ) {
    if (filterValues.minRent !== null || filterValues.maxRent !== null) {
      count = count + 1;
    }
  }
  if (filterValues.marketingType === MarketingType.BUY && filterValues.objectType != ObjectType.FORECLOSURE) {
    if (filterValues.minPrice !== null || filterValues.maxPrice !== null) {
      count = count + 1;
    }
  }

  switch (filterValues.objectType) {
    case ObjectType.FLAT:
      count = count + size(filterValues.flatTypes);
      count = count + size(filterValues.equipment);

      if (filterValues.minLivingSpace !== null || filterValues.maxLivingSpace !== null) {
        count = count + 1;
      }

      if (filterValues.minNumberRooms !== null || filterValues.maxNumberRooms !== null) {
        count = count + 1;
      }

      if (trim(filterValues.isNewBuilding) !== '') {
        count = count + 1;
      }
      break;
    case ObjectType.HOUSE:
      count = count + size(filterValues.houseTypes);
      count = count + size(filterValues.equipment);

      if (filterValues.minLivingSpace !== null || filterValues.maxLivingSpace !== null) {
        count = count + 1;
      }

      if (filterValues.minPropertySize !== null || filterValues.maxPropertySize !== null) {
        count = count + 1;
      }

      if (filterValues.minNumberRooms !== null || filterValues.maxNumberRooms !== null) {
        count = count + 1;
      }

      if (trim(filterValues.isNewBuilding) !== '') {
        count = count + 1;
      }
      break;
    case ObjectType.PROPERTY:
      count = count + size(filterValues.propertyTypes);

      if (filterValues.minPropertySize !== null || filterValues.maxPropertySize !== null) {
        count = count + 1;
      }

      if (trim(filterValues.conditionDevelopment) !== '') {
        count = count + 1;
      }
      break;
    case ObjectType.INTEREST:
      count = count + size(filterValues.interestTypes);
      break;
    case ObjectType.BUSINESS:
      if (filterValues.minTotalSpace !== null || filterValues.maxTotalSpace !== null) {
        count = count + 1;
      }
      break;
  }

  return count;
};
