import { Box } from '@mui/material';
import { LoadingDots } from '@portals/core/src/components/LoadingDots/LoadingDots';
import { theme } from '@portals/core/src/themes/immobilien/main';
import React, { useMemo } from 'react';

import { Carousel } from '..';
import { AspectMedia } from './AspectMedia/AspectMedia';
import { getStyles } from './ImageContainer.styles';
import { PartnerLogo } from './PartnerLogo/PartnerLogo';

export type ImageContainerProps = {
  imageList: string[];
  title: string;
  partnerLogo: string;
  bookmarkButton?: React.ReactNode;
};

export const ImageContainer: React.FC<ImageContainerProps> = ({ imageList, partnerLogo, title, bookmarkButton }) => {
  const styles = getStyles(theme);

  const renderedImageComponentList = useMemo(() => {
    return imageList.map((image, key) => (
      <AspectMedia key={key} media={{ alternativeText: title, url: image }} aspectWidth={3} aspectHeight={2} />
    ));
  }, [imageList, title]);

  return (
    <Box className="estate-list-item-image-container" sx={styles.imageContainer}>
      <Box sx={styles.loadingOverlay}>
        <LoadingDots />
      </Box>
      <Carousel sx={styles.carousel}>{renderedImageComponentList}</Carousel>
      {partnerLogo && <PartnerLogo imgSrc={partnerLogo} />}
      {bookmarkButton && <Box sx={styles.bookmarkButtonContainer}>{bookmarkButton}</Box>}
    </Box>
  );
};

ImageContainer.displayName = 'ImageContainer';
