import { Box } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import i18next from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { EstateListItem, EstateListItemAd, UserContext } from '../../../components';
import { loginConfig } from '../../../context';
import { deleteEstate, fetchEstates, getEyecatcher, getMainfacts } from '../../../utils';
import { DeleteDialog } from './DeleteDialog';

export type image = {
  m: string;
};

export type EstateAdItem = {
  userEstateId: string;
  sipEstateId: string;
  userId: string;
  moderationId: string;
  modifiedDate: Date;
  state: string;
  estateData: string;
  priceBuy?: string;
  geoCity: string;
  geoStreet: string;
  geoStreetNo: string;
  geoZip: string;
  images: Array<image>;
  spaceLiving: string;
  spaceProperty?: string;
  numRoom?: number;
  textEstateHeading: string | undefined;
  publish?: boolean;
};

type UserEstateId = {
  userEstateId: string;
};

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateAdList = (): React.ReactElement => {
  const userContext = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [estates, setEstates] = useState<Array<EstateAdItem>>([]);
  const [currentEstateForDeleteDialog, setCurrentEstateForDeleteDialog] = useState<UserEstateId | null>(null);
  const loadSavedEstates = async () => {
    const data = await fetchEstates(loginConfig.userApiUrl, userContext.userData.userId);
    if (data !== null) {
      const publishedEstates = data.filter((estate) => estate.publish);
      await setEstates(publishedEstates);
    }
  };
  const handleDeleteDialog = useCallback(async (event: Event, userEstateId: string) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentEstateForDeleteDialog({ userEstateId: userEstateId });
  }, []);

  const handleClose = useCallback(() => {
    setCurrentEstateForDeleteDialog(null);
  }, []);

  const handleDeleteEstate = async () => {
    await deleteEstate(loginConfig.userApiUrl, userContext.userData.userId, currentEstateForDeleteDialog.userEstateId)
      .then(loadSavedEstates)
      .then(() => {
        enqueueSnackbar(t('userDashboard.estateAdList.dialog.snackbar.success'), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t('userDashboard.estateAdList.dialog.snackbar.error'), { variant: 'error' });
      })
      .finally(handleClose);
  };

  useEffect(() => {
    loadSavedEstates();
  }, []);

  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const estateListItems = useMemo(() => {
    return estates.map((estate: EstateAdItem, index: number) => {
      const price = estate.priceBuy;
      return (
        <Box
          key={estate.userEstateId}
          data-testid="estateAdListItem"
          mt={{ xs: 6, md: 6, text: 0, lg: 0 }}
          mb={8}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            flexGrow: 1,
          }}
        >
          <EstateListItem
            id={estate.sipEstateId}
            title={`${estate.textEstateHeading}`}
            subtitle={estate.geoCity}
            images={[estate.images[0]?.m]}
            price={formatter.format(Number(price))}
            mainFacts={getMainfacts(estate)}
            eyeCatcher={getEyecatcher(estate, t)}
            isLastItem={index === estates.length - 1}
            partnerLogo={null}
          />
          <EstateListItemAd estate={estate} handleDeleteDialog={handleDeleteDialog} />
        </Box>
      );
    });
  }, [estates]);

  if (estates.length === 0) {
    return null;
  }
  return (
    <>
      <Typography variant="h3" innerPadding="horizontal">
        {t('userDashboard.estateAdList.headline')} ({estates.length})
      </Typography>
      <DeleteDialog
        currentEstateForDeleteDialog={currentEstateForDeleteDialog}
        /* eslint-disable-next-line react/jsx-no-bind */
        handleDeleteEstate={handleDeleteEstate}
        handleClose={handleClose}
      />
      {estateListItems}
    </>
  );
};

EstateAdList.displayName = 'EstateAdList';
