import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';

import type { FilterValuesProps } from '../../../../types';
import { getNumberOfActiveFilters as getNumberOfActiveFiltersImmobilienApi } from './ImmobilienApi/FilterAndResultControlView.utils';
import { getNumberOfActiveFilters as getNumberOfActiveFiltersSipApi } from './SipApi/FilterAndResultControlView.utils';

export const getNumberOfActiveFilters = (filterValues: FilterValuesProps): number => {
  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: only use getNumberOfActiveFiltersImmobilienApi()
  const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
  return useImmobilienApi
    ? getNumberOfActiveFiltersImmobilienApi(filterValues)
    : getNumberOfActiveFiltersSipApi(filterValues);
};
