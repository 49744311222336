import { Box, IconButton, ThemeProvider, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Dialog } from '@portals/core/src/components/Dialog/Dialog';
import { LoadingDots } from '@portals/core/src/components/LoadingDots/LoadingDots';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { Close } from '@portals/icons';
import i18next from 'i18next';
import React, { useCallback, useState } from 'react';

export type DialogStateType = {
  userEstateId: string;
};

type DeleteDialogProps = {
  currentEstateForDeleteDialog: DialogStateType;
  handleDeleteEstate: () => void;
  handleClose: () => void;
};

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  currentEstateForDeleteDialog,
  handleDeleteEstate,
  handleClose,
}: DeleteDialogProps) => {
  const theme = useTheme();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseClick = useCallback(() => {
    if (!isDeleting) {
      handleClose();
    }
  }, [isDeleting, handleClose]);

  const handleDeleteClick = useCallback(async () => {
    if (!isDeleting) {
      setIsDeleting(true);
      try {
        await handleDeleteEstate();
        handleClose();
      } catch (error) {
        // snackbar is handled in handleDeleteEstate
      } finally {
        setIsDeleting(false);
      }
    }
  }, [isDeleting, handleDeleteEstate, handleClose]);
  const closeButton = (
    <Box textAlign="right">
      <IconButton onClick={handleClose} sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}>
        <Close color="secondary" />
      </IconButton>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={currentEstateForDeleteDialog !== null} onClose={handleDeleteClick}>
        {closeButton}
        <Typography variant="body1" innerPadding="horizontal">
          {t('userDashboard.estateAdList.dialog.headline')}
        </Typography>
        <Box width="100%" pt={5} mb="auto" display="flex" justifyContent="space-evenly" gap={6}>
          <Button color="secondary" onClick={handleDeleteClick} disabled={isDeleting}>
            {!isDeleting && t('userDashboard.estateAdList.dialog.button.delete')}
            {isDeleting && <LoadingDots />}
          </Button>
          <Button color="secondary" onClick={handleCloseClick} disabled={isDeleting}>
            {t('userDashboard.estateAdList.dialog.button.cancel')}
          </Button>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

DeleteDialog.displayName = 'DeleteDialog';
