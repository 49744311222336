import '@portals/core-immobilien/src/config/yup';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { GlobalTypography } from '@portals/core/src/components/GlobalTypography/GlobalTypography';
import { LoginHandler } from '@portals/core-immobilien/src/components';
import {
  BookmarkProvider,
  CatalogsProvider,
  IF6Provider,
  ImmobilienApiSwitchProvider,
} from '@portals/core-immobilien/src/context';
import coreImmoDe from '@portals/core-immobilien/src/locales/de/core-immobilien.json';
import formsDe from '@portals/forms/translations/de.json';
import { RootContextCollection } from '@portals/root/src/contexts/RootContextCollection';
import { ServiceBoxContextProvider } from '@portals/root/src/contexts/service-box';
import { SparkasseAssignmentFormProvider } from '@portals/root/src/contexts/sparkasse-assignment-form';
import createEmotionCache from '@portals/root/src/utils/createEmotionCache';
import { FrontendApiClient } from '@portals/sip-client-data/src/frontend/FrontendApiClient';
import { FrontendConfig } from '@portals/sip-client-data/src/frontend/FrontendConfig';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import i18next, { ResourceLanguage } from 'i18next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';

import { TagManager } from '../src/components/TagManager/TagManager';
import { theme } from '../src/themes/immobilien/main';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  useImmobilienApi?: string;
}

export const i18NextConfig = {
  fallbackLng: 'de',
  ns: ['portals'],
  defaultNS: 'portals',
  debug: false,
  resources: {
    de: {
      ...formsDe,
      ...coreImmoDe,
    } as ResourceLanguage,
  },
};

if (!i18next.isInitialized) {
  i18next.init(i18NextConfig, (err) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  });
}
const isContentPage = (url) => {
  const notContentPageRoutes = ['/expose/', '/immobilien/treffer'];
  const found = notContentPageRoutes.some((page) => {
    return url.indexOf(page) !== -1;
  });
  return !found;
};

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
  useImmobilienApi,
}: MyAppProps): React.ReactElement {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      if (isContentPage(url)) {
        // eslint-disable-next-line @if6/fi-no-location
        window.location = url;
        return false;
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  });

  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION
  useEffect(() => {
    ConfigProvider.getConfig().set('USE_IMMOBILIEN_API', useImmobilienApi);
  }, [useImmobilienApi]);

  return (
    <LoginHandler>
      <BookmarkProvider>
        <Head>
          <meta key="meta_viewport" name="viewport" content="width=device-width, initial-scale=1.0" />
        </Head>
        <ImmobilienApiSwitchProvider initialUseImmobilienApi={useImmobilienApi === 'true'}>
          <CacheProvider value={emotionCache}>
            <IF6Provider>
              <SparkasseAssignmentFormProvider>
                <SnackbarProvider maxSnack={3}>
                  <ThemeProvider theme={theme}>
                    <ServiceBoxContextProvider>
                      <RootContextCollection>
                        <CatalogsProvider useImmobilienApi={useImmobilienApi === 'true'}>
                          <TagManager />
                          <CssBaseline />
                          <GlobalTypography />
                          <Component {...pageProps} />
                        </CatalogsProvider>
                      </RootContextCollection>
                    </ServiceBoxContextProvider>
                  </ThemeProvider>
                </SnackbarProvider>
              </SparkasseAssignmentFormProvider>
            </IF6Provider>
          </CacheProvider>
        </ImmobilienApiSwitchProvider>
      </BookmarkProvider>
    </LoginHandler>
  );
}

// TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION
MyApp.getInitialProps = async () => {
  return { useImmobilienApi: process.env.USE_IMMOBILIEN_API || 'false' };
};

export default appWithTranslation(MyApp);

const config = new FrontendConfig();
ConfigProvider.setConfig(config);

ApiClientProvider.setApiClient(new FrontendApiClient());
