export type EstateBaseData = {
  id: string;
  title: string;
  subtitle: string;
};

export type EstateListItem = EstateBaseData & {
  images: string[];
  price: string;
  eyeCatcher: EstateEyecatcher[];
  mainFacts: EstateMainFact[];
  partnerLogo: string | null;
  lat: number;
  lng: number;
};

export type EstateItem = EstateListItem & {
  priceData: EstateMainFact;
};

export type Resources = {
  url: string;
  size: string;
  webp: string;
};

export type GalleryItems = {
  type: 'image' | '360tour' | 'floorplan' | 'video';
  description: string;
  resources: Array<Resources>;
  webpUrl?: string;
  defaultUrl?: string;
  defaultImageType?: string;
  seoTitle?: string;
};

export type FrontendContent = {
  type: string;
  data: any;
};

export type FrontendItem = {
  label: string;
  contents: Array<FrontendContent>;
  name:
    | 'priceData'
    | 'locationData'
    | 'objectDescriptionData'
    | 'equipmentData'
    | 'objectData'
    | 'conditionData'
    | 'otherData'
    | 'foreclosureData'
    | 'moreInformationData';
};

export type Broker = {
  phone: string;
  mobilePhone: string;
  email: string;
  imageUrl: string;
  fullSalutation: string;
  firstName: string;
  lastName: string;
};

export type ProviderAddress = {
  streetName: string;
  streetNumber: string;
  zip: string;
  city: string;
};

export type Provider = {
  company: string;
  address: ProviderAddress;
};

export type Partner = {
  headline: string;
  text: string;
  logoUrl: string;
};

export type ObjectTypes = 'house' | 'flat' | 'property' | 'office' | 'commerce' | 'catering' | 'hall' | 'interest';

export type PriceIndicator = {
  zip: string;
  type: ObjectTypes;
  price: number;
};

export type Energy = {
  efficiencyClass: string;
  powerConsumption: number;
};

export type ImprintData = {
  company: string;
  structured: { [key: string]: string };
  plain: string;
  info: string;
  odr: string;
};

export type Contact = {
  privacyPolicy: string;
  consumerInformation: string;
  contactPhoneRequired: boolean;
  contactAddressRequired: boolean;
};

export type Seo = {
  title: string;
  description: string;
  noIndex: boolean;
};

export type Financing = {
  hide: boolean;
  linkParams: any;
};

export type Address = {
  zip: string;
  city: string;
  street: string | null;
  streetNumber: string | null;
};

export type EstateDetail = {
  id: string;
  externId: string;
  images: Array<GalleryItems>;
  instituteCode?: string;
  title: string;
  subtitle: string;
  frontendItems: Array<FrontendItem>;
  moreInformationData: Array<MoreInformationEntry>;
  lat: number;
  lng: number;
  address: Address;
  partner?: Partner;
  broker: Broker;
  provider: Provider;
  priceIndicator: PriceIndicator;
  priceNumeric: number;
  mainFacts: Array<EstateMainFact>;
  energy: Energy;
  financing: Financing;
  imprint: ImprintData;
  contact: Contact;
  seo: Seo;
  isUserEstate: boolean;
  polygon?: Array<CoordinateWithStringValues>;
};

export type MoreInformationEntry = {
  anhangtitel: string;
  url: string;
};

export type MainFactProps = {
  list: Array<EstateMainFact>;
};

export type EstateMainFact = {
  category?: MainfactCategory;
  label: string;
  value: string;
};

export enum MainfactCategory {
  ROOMS = 'ROOMS',
  AREA = 'AREA',
}

export type EstateListResponse = {
  totalItems: number;
  page: number;
  pageCount: number;
  estates: Array<EstateItem>;
};

export type EstateCountResponse = {
  totalItems: number;
};

export enum ReturnType {
  REACT_FRONTEND = 'react_frontend',
  COUNT = 'count',
  TEASER_LIST = 'teaser_list',
  ID_ONLY = 'id_only',
}

export enum ObjectType {
  HOUSE = 'house',
  FLAT = 'flat',
  PROPERTY = 'property',
  OFFICE = 'office',
  COMMERCE = 'commerce',
  CATERING = 'catering',
  HALL = 'hall',
  FORECLOSURE = 'foreclosure',
  INTEREST = 'interest',
  BUSINESS = 'business',
  OTHERS = 'others',
}

export enum MarketingType {
  BUY = 'buy',
  RENT = 'rent',
}

export enum UsageType {
  BUSINESS = 'business',
  RESIDENTIAL = 'residential',
}

export type EstateSearchProps = {
  zipCityEstateId?: string;
  perimeter?: number;
  limit?: number;
  page?: number;
  sortBy?: string;
  returnData?: ReturnType;
  objectType?: ObjectType;
  estateTypeId?: number;
  marketingType?: MarketingType;
  usageType?: UsageType;

  minPrice?: number;
  maxPrice?: number;
  minRent?: number;
  maxRent?: number;
  minMarketValue?: number;
  maxMarketValue?: number;
  minLease?: number;
  maxLease?: number;
  minLivingSpace?: number;
  maxLivingSpace?: number;
  minPropertySize?: number;
  maxPropertySize?: number;
  minTotalSpace?: number;
  maxTotalSpace?: number;
  minNumberRooms?: number;
  maxNumberRooms?: number;

  flatTypes?: string[];
  houseTypes?: string[];
  propertyTypes?: string[];
  interestTypes?: string[];
  equipment?: string[];
  conditionDevelopment?: string;
  isNewBuilding?: string;
};
export default ObjectType;
export type EstateEyecatcherType = 'default' | 'highlighted';
export type EstateEyecatcher = {
  type: EstateEyecatcherType;
  label: string;
};

export type Coordinate = {
  lat: number;
  lon: number;
};

export type CoordinateWithStringValues = {
  lat: string;
  lon: string;
};

export type EstateResponse = {
  eyecatcher: Array<EstateEyecatcher>;
  gallery: string[];
  geo_label: string;
  headline: string;
  id: string;
  main_facts: Array<EstateMainFact>;
  object_type: string;
  partner_logo: string | null;
  location: Coordinate;
  broker: Broker;
  provider: Provider;
};
export const VALID_ENERGY_EFFICIENCY_CLASS_NAMES = ['A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'] as const;

// prettier-ignore
export type EnergyEfficiencyClass = typeof VALID_ENERGY_EFFICIENCY_CLASS_NAMES[number];

export type CatalogItem = {
  id: number;
  code: string;
  runtimeModel: string;
};

export type CatalogEntity = {
  items?: Array<CatalogItem>;
  id: number;
  code: string;
  isDefault?: boolean;
  runtimeModel: string;
};

export type CatalogsResponse = {
  estateSubTypes?: Array<CatalogEntity>;
  sortings?: Array<CatalogEntity>;
  salutations?: Array<CatalogEntity>;
  contactRequestWishes?: Array<CatalogEntity>;
  contactRequestPreferences?: Array<CatalogEntity>;
  offerTypes?: Array<CatalogEntity>;
  roomsFrom?: Array<CatalogEntity>;
  roomsTo?: Array<CatalogEntity>;
  radii?: Array<CatalogEntity>;
};

export type AutoCompleteOption = {
  value: string;
  label: string;
};

export type Location = {
  displayCode: string;
  latitude: number;
  longitude: number;
};

export type FinanceData = {
  bankCode: string;
  price: number;
  downPayment: number;
  referenceId?: string;
  objectType?: string;
  zip?: string;
  street?: string;
  streetNumber?: string;
  livingSpace?: number;
  landArea?: number;
  constructionYear?: number;
  place?: string;
  basement?: boolean;
  floorCount?: number;
  garageCount?: number;
  parkingSpaceCount?: number;
  energyEfficiencyStandard?: string;
  energyEfficiencyClass?: string;
  usageType?: string;
  brokerageFee?: number;
  months?: number;
  repaymentRate?: number;
};

export type MapQueryParams = {
  lng: number;
  lat: number;
};

export type TravelTimeQueryParams = {
  startLat: number;
  startLng: number;
  endLat: number;
  endLng: number;
  text: string;
};

export type EstateContactMessageProps = {
  estateId?: string;
  instituteCode?: string;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phone?: string;
  message?: string;
  street?: string;
  streetNumber?: string;
  zip?: string;
  city?: string;
};

export type EstateContactRequestProps = {
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
  street?: string;
  houseNumber?: string;
  zip?: string;
  town?: string;
  request?: string;
};

export type UserEventData = {
  type: string;
  regio_client_blz?: string;
  estate_id: string;
  session_id?: string;
  operator?: string;
  extras?: Record<string, number | string>;
};
export interface EstateObjectTypeOptions {
  house?: boolean;
  flat?: boolean;
  property?: boolean;
  commerce?: boolean;
  hall?: boolean;
  catering?: boolean;
  office?: boolean;
  others?: boolean;
}
