import { apiFrontendMapping } from '@portals/sip-client-data/src/general/ApiClientMapping/ApiClientMapping';
import {
  CatalogEntity,
  EstateObjectTypeOptions,
  EstateSearchProps,
  MarketingType,
  ObjectType,
} from '@portals/sip-client-data/src/general/ApiClientTypes';
import { TFunction } from 'i18next';
import { find, forIn, get, indexOf, isArray, isEqual, join, map, omitBy, pickBy } from 'lodash-es';
import { ParsedUrlQuery } from 'querystring';

import { defaultPerimeter } from '../config';
import { type EstateSearchMainFormProps } from '../types';

export const commonApiParams = [
  'zipCityEstateId',
  'perimeter',
  'limit',
  'page',
  'sortBy',
  'returnData',
  'objectType',
  'estateTypeId',
  'marketingType',
  'usageType',
  'estateIds[]',
];
const priceApiParams = ['minPrice', 'maxPrice'];
const marketValueApiParams = ['minMarketValue', 'maxMarketValue'];
const rentApiParams = ['minRent', 'maxRent'];
const leaseApiParams = ['minLease', 'maxLease'];
const livingSpaceApiParams = ['minLivingSpace', 'maxLivingSpace'];
const propertySizeApiParams = ['minPropertySize', 'maxPropertySize'];
const numberRoomsApiParams = ['minNumberRooms', 'maxNumberRooms'];
const totalSpaceApiParams = ['minTotalSpace', 'maxTotalSpace'];
const flatTypesApiParams = ['flatTypes[]'];
const houseTypesApiParams = ['houseTypes[]'];
const propertyTypesApiParams = ['propertyTypes[]'];
const interestTypesApiParams = ['interestTypes[]'];
const equipmentApiParams = ['equipment[]'];
const conditionDevelopmentApiParams = ['conditionDevelopment'];
const isNewBuildingApiParams = ['isNewBuilding'];

/**
 * changes param[] to param if the value is an Array
 *
 * Reasoning: in stringifyUrl if param[]=Array, the generated query parameter will be "param[][]", which needs to be corrected.
 * If param[]=string, then the generated parameter will be "param[]=string", as intended.
 *
 * This is used in url generation
 * @param searchParams
 */
export const removeBrackets = (searchParams: EstateSearchProps): EstateSearchProps => {
  const output = {};
  forIn(searchParams, (val, key) => {
    if (val !== '') {
      const newKey = Array.isArray(val) ? key.replace('[]', '') : key;
      output[newKey] = val;
    }
  });
  return output;
};

/**
 * SIP-9172
 * deprecated
 * changes equipment[][] to equipment[] in given object.
 * used as quickfix, best solution would be to have no double brackets in link generation
 * @param searchParams
 */
export const removeDoubleBracketsAndEmptyFieldsInBackend = (searchParams: EstateSearchProps): EstateSearchProps => {
  const output = {};
  forIn(searchParams, (val, key) => {
    if (val !== '') {
      const newKey = key.replace('[][]', '[]');
      output[newKey] = val;
    }
  });
  return output;
};

export const convertSearchParamsToQuery = (searchParams: EstateSearchProps): ParsedUrlQuery => {
  const query = {};
  searchParams = removeDoubleBracketsAndEmptyFieldsInBackend(searchParams);
  forIn(apiFrontendMapping, (apiParam, queryParam) => {
    if (searchParams[queryParam] !== undefined) {
      query[apiParam] = searchParams[queryParam];
    }
  });

  return query;
};

export const convertApiParamsToSearchParams = (apiParams: ParsedUrlQuery): EstateSearchProps => {
  const searchParams = {};

  forIn(apiFrontendMapping, (apiParam, queryParam) => {
    const convertedApiParam = apiParam.replace('[]', '');
    if (apiParams[convertedApiParam]) {
      searchParams[queryParam.replace('[]', '')] = apiParams[convertedApiParam];
    }
  });

  return searchParams;
};

export const removeEmptyParams = (queryParams: ParsedUrlQuery): ParsedUrlQuery => {
  return omitBy(queryParams, (value) => {
    return value === undefined || value === null || value === '' || (isArray(value) && value.length === 0);
  });
};

export const removeEmptyObjectParams = (queryParams: Record<string, any>): Record<string, any> => {
  return omitBy(queryParams, (value) => {
    return value === undefined || value === null || value === '' || (isArray(value) && value.length === 0);
  });
};

export const prefillDefaultSearchValues = (values: EstateSearchMainFormProps): EstateSearchMainFormProps => {
  return {
    zipCityEstateId: values.zipCityEstateId,
    maxPrice: values.maxPrice,
    maxMarketValue: values.maxMarketValue,
    maxRent: values.maxRent,
    maxLease: values.maxLease,
    perimeter: values.perimeter || defaultPerimeter,
    minLivingSpace: values.minLivingSpace,
    minNumberRooms: values.minNumberRooms,
    minTotalSpace: values.minTotalSpace,
    minPropertySize: values.minPropertySize,
    marketingType: values.marketingType,
    objectType: values.objectType,
    estateTypeId: values.estateTypeId,
    usageType: values.usageType,
  } as EstateSearchMainFormProps;
};

export const getObjectTypeOptions = (t: TFunction | (() => string), marketingType: MarketingType) => {
  switch (marketingType) {
    case MarketingType.BUY:
      return [
        {
          value: ObjectType.HOUSE,
          label: t('objectTypes.house'),
          marketingTypeObjectTypeLabel: t('estateSearch.buy.house'),
          whitelistedApiParams: [
            ...commonApiParams,
            ...priceApiParams,
            ...livingSpaceApiParams,
            ...propertySizeApiParams,
            ...numberRoomsApiParams,
            ...houseTypesApiParams,
            ...equipmentApiParams,
            ...isNewBuildingApiParams,
          ],
        },
        {
          value: ObjectType.FLAT,
          label: t('objectTypes.flat'),
          marketingTypeObjectTypeLabel: t('estateSearch.buy.flat'),
          whitelistedApiParams: [
            ...commonApiParams,
            ...priceApiParams,
            ...livingSpaceApiParams,
            ...numberRoomsApiParams,
            ...flatTypesApiParams,
            ...equipmentApiParams,
            ...isNewBuildingApiParams,
          ],
        },
        {
          value: ObjectType.PROPERTY,
          label: t('objectTypes.property'),
          marketingTypeObjectTypeLabel: t('estateSearch.buy.property'),
          whitelistedApiParams: [
            ...commonApiParams,
            ...priceApiParams,
            ...propertySizeApiParams,
            ...propertyTypesApiParams,
            ...conditionDevelopmentApiParams,
          ],
        },
        {
          value: ObjectType.FORECLOSURE,
          label: t('objectTypes.foreclosure'),
          marketingTypeObjectTypeLabel: t('estateSearch.buy.foreclosure'),
          whitelistedApiParams: [...commonApiParams, ...marketValueApiParams],
        },
        {
          value: ObjectType.INTEREST,
          label: t('objectTypes.interest'),
          marketingTypeObjectTypeLabel: t('estateSearch.buy.interest'),
          whitelistedApiParams: [...commonApiParams, ...priceApiParams, ...interestTypesApiParams],
        },
        {
          value: ObjectType.BUSINESS,
          label: t('objectTypes.business'),
          marketingTypeObjectTypeLabel: t('estateSearch.buy.business'),
          whitelistedApiParams: [...commonApiParams, ...priceApiParams, ...totalSpaceApiParams],
        },
      ];
    case MarketingType.RENT:
      return [
        {
          value: ObjectType.HOUSE,
          label: t('objectTypes.house'),
          marketingTypeObjectTypeLabel: t('estateSearch.rent.house'),
          whitelistedApiParams: [
            ...commonApiParams,
            ...rentApiParams,
            ...livingSpaceApiParams,
            ...propertySizeApiParams,
            ...numberRoomsApiParams,
            ...houseTypesApiParams,
            ...equipmentApiParams,
            ...isNewBuildingApiParams,
          ],
        },
        {
          value: ObjectType.FLAT,
          label: t('objectTypes.flat'),
          marketingTypeObjectTypeLabel: t('estateSearch.rent.flat'),
          whitelistedApiParams: [
            ...commonApiParams,
            ...rentApiParams,
            ...livingSpaceApiParams,
            ...numberRoomsApiParams,
            ...flatTypesApiParams,
            ...equipmentApiParams,
            ...isNewBuildingApiParams,
          ],
        },
        {
          value: ObjectType.PROPERTY,
          label: t('objectTypes.property'),
          marketingTypeObjectTypeLabel: t('estateSearch.rent.property'),
          whitelistedApiParams: [
            ...commonApiParams,
            ...leaseApiParams,
            ...propertySizeApiParams,
            ...propertyTypesApiParams,
            ...conditionDevelopmentApiParams,
          ],
        },
        {
          value: ObjectType.BUSINESS,
          label: t('objectTypes.business'),
          marketingTypeObjectTypeLabel: t('estateSearch.rent.business'),
          whitelistedApiParams: [...commonApiParams, ...rentApiParams, ...totalSpaceApiParams],
        },
      ];
    default:
      return [
        {
          value: '',
          label: '',
          marketingTypeObjectTypeLabel: '',
          whitelistedApiParams: commonApiParams,
        },
      ];
  }
};

export const getEstateTypeIdOptions = (estateSubTypes: Array<CatalogEntity>, marketingType?: MarketingType) => {
  const defaultItem = {
    value: '',
    label: '',
    marketingTypeObjectTypeLabel: '',
    whitelistedApiParams: commonApiParams,
  };
  if (!estateSubTypes || estateSubTypes.length === 0) {
    return [defaultItem];
  }
  return (
    estateSubTypes
      ?.filter((estateSubType) => estateSubType.items.length > 0 && estateSubType.items[0]?.id < 0)
      ?.map((estateSubType) => {
        const firstItem = estateSubType.items[0];
        return {
          value: firstItem?.id * -1,
          label: firstItem?.code,
          marketingTypeObjectTypeLabel: `${firstItem?.code}${
            marketingType ? (marketingType === MarketingType.RENT ? ' mieten' : ' kaufen') : ''
          }`,
          whitelistedApiParams: commonApiParams,
        };
      }) ?? [defaultItem]
  );
};

export const getMarketingTypeObjectTypeLabel = ({
  t,
  marketingType,
  objectType,
}: {
  t: TFunction;
  marketingType?: MarketingType;
  objectType: ObjectType;
}) => {
  const objectTypes = getObjectTypeOptions(t, marketingType);
  const objectTypeObject = find(objectTypes, { value: objectType });
  return get(objectTypeObject, 'marketingTypeObjectTypeLabel', '');
};

export const getMarketingTypeEstateTypeIdLabel = ({
  estateSubTypes,
  marketingType,
  estateTypeId,
}: {
  estateSubTypes: Array<CatalogEntity>;
  marketingType?: MarketingType;
  estateTypeId: number;
}) => {
  const estateTypeOptions: Array<any> = getEstateTypeIdOptions(estateSubTypes, marketingType);
  const estateTypeObject = estateTypeOptions?.find((item) => item.value === estateTypeId);
  return estateTypeObject?.marketingTypeObjectTypeLabel ?? '';
};

const emptyTranslator: () => string = () => {
  return '';
};

const getWhitelistApiParams = (marketingType: MarketingType, objectType: ObjectType) => {
  const objectTypes = getObjectTypeOptions(emptyTranslator, marketingType);
  const objectTypeObject = find(objectTypes, { value: objectType }) || objectTypes[0];
  return get(objectTypeObject, 'whitelistedApiParams', []);
};

export const removeNotWhitelistedParams = (searchParams) => {
  const filterParams = getWhitelistApiParams(searchParams.marketingType, searchParams.objectType);
  return pickBy(searchParams, (value, key) => {
    return indexOf(filterParams, key) > -1;
  });
};

const getWhitelistApiParamsByEstateTypeId = (
  estateSubTypes: Array<CatalogEntity>,
  marketingType: MarketingType,
  estateTypeId: number
) => {
  const estateTypeIdOptions = getEstateTypeIdOptions(estateSubTypes, marketingType);
  const objectTypeObject = find(estateTypeIdOptions, { value: estateTypeId }) || estateTypeIdOptions[0];
  return get(objectTypeObject, 'whitelistedApiParams', []);
};

export const removeNotWhitelistedParamsByEstateTypeId = (estateSubTypes: Array<CatalogEntity>, searchParams) => {
  const filterParams = getWhitelistApiParamsByEstateTypeId(
    estateSubTypes,
    searchParams.marketingType,
    searchParams.estateTypeId
  );
  return pickBy(searchParams, (value, key) => {
    return indexOf(filterParams, key) > -1;
  });
};

export const mapZipCityEstateCollectionToSingleString = (options: { value: number; label: string }) => {
  const valueLabels = map(options, (option) => {
    return `${option.value}__${option.label}`;
  });
  return join(valueLabels, ';');
};

export const apiFormattedParamSetsAreEqual = (
  params1: ParsedUrlQuery,
  params2: ParsedUrlQuery,
  ignoreFields: Array<string>
): boolean => {
  params1 = removeEmptyParams(params1);
  params2 = removeEmptyParams(params2);

  for (const fieldName in apiFrontendMapping) {
    const apiFieldName = apiFrontendMapping[fieldName].replace('[]', '');
    if (ignoreFields.includes(apiFieldName)) {
      continue;
    }
    if (!isArray(params1[apiFieldName]) && params1[apiFieldName] != params2[apiFieldName]) {
      return false;
    }

    if (
      isArray(params1[apiFieldName]) &&
      (!isArray(params2[apiFieldName]) ||
        !isEqual((params1[apiFieldName] as Array<string>).sort(), (params2[apiFieldName] as Array<string>).sort()))
    ) {
      return false;
    }
  }

  return true;
};

export function determineCorrespondingSearchParam(fieldName: string): string | null {
  if (fieldName.includes('min')) {
    return fieldName.replace('min', 'max');
  }

  if (fieldName.includes('max')) {
    return fieldName.replace('max', 'min');
  }
  return null;
}

export function fieldHasValue(fieldValue: string): boolean {
  return fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
}

export function estateObjectTypesToQueryString(objectTypes: EstateObjectTypeOptions): string {
  const ignoreAttributes = ['id', '__component'];
  if (!objectTypes) {
    return '';
  }
  const objectTypesString = Object.entries(objectTypes)
    .filter(([key]) => !ignoreAttributes.includes(key))
    .reduce((prev, [key, value]) => {
      return value ? `${prev} ${key}` : prev;
    }, '');

  return objectTypesString.trim().replace(/ /g, ';');
}
