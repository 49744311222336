import { useSPKCookie } from '@portals/forms/src/hooks/useSPKCookie/useSPKCookie';

interface BankData {
  bankCode: string;
  bankName: string;
}

export const useBankData = (): BankData => {
  const cookieData = useSPKCookie();
  const bankCode = cookieData.bankCode;
  const bankName = cookieData.bankName;

  return { bankCode, bankName };
};
