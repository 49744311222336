import React, { useCallback, useContext } from 'react';

import { Criteria } from '../../../config';
import { ImmobilienApiSwitchContext } from '../../../context';
import type { FilterValuesProps } from '../../../types';
import { FilterDrawer } from '../FilterDrawer/FilterDrawer';
import { FilterDrawer as FilterDrawerImmobilienApi } from '../FilterDrawer/ImmobilienApi/FilterDrawer';
import { FiltersFormWrapper } from './FiltersFormWrapper/FiltersFormWrapper';

export type FilterDrawerOptions = {
  visible: boolean;
  criteria: Criteria | null;
  showAllFilter: boolean;
};

export type VisibleFilterDrawerOptions = Omit<FilterDrawerOptions, 'visible'>;

interface FiltersViewProps {
  filterValues: FilterValuesProps;
  resultFilterDrawerOptions: FilterDrawerOptions;
  setResultFilterDrawerOptions: React.Dispatch<React.SetStateAction<FilterDrawerOptions>>;
  setFilterValues: React.Dispatch<React.SetStateAction<FilterValuesProps>>;
  currentPage: React.MutableRefObject<number>;
  close: () => void;
  resetFilters: () => void;
}

export const FiltersView: React.FC<FiltersViewProps> = ({
  filterValues,
  resultFilterDrawerOptions,
  setResultFilterDrawerOptions,
  setFilterValues,
  currentPage,
  close,
  resetFilters,
}) => {
  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch and context
  const { useImmobilienApi } = useContext(ImmobilienApiSwitchContext);

  const changeCriteria = useCallback((criteria: Criteria) => {
    currentPage.current = 1;
    setResultFilterDrawerOptions((prevState) => {
      return {
        ...prevState,
        criteria,
      };
    });
  }, []);

  const changeFilterValues = useCallback(
    (changedFilterValues: FilterValuesProps) => {
      currentPage.current = 1;
      setFilterValues(changedFilterValues);
    },
    [setFilterValues, currentPage]
  );

  return (
    <FiltersFormWrapper
      filterValues={filterValues}
      criteria={resultFilterDrawerOptions.criteria}
      showAllFilter={resultFilterDrawerOptions.showAllFilter}
      changeCriteria={changeCriteria}
      changeFilterValues={changeFilterValues}
    >
      {useImmobilienApi ? (
        <FilterDrawerImmobilienApi
          filterValues={filterValues}
          visible={resultFilterDrawerOptions.visible}
          criteria={resultFilterDrawerOptions.criteria}
          showAllFilter={resultFilterDrawerOptions.showAllFilter}
          changeCriteria={changeCriteria}
          changeFilterValues={changeFilterValues}
          close={close}
          resetFilters={resetFilters}
        />
      ) : (
        <FilterDrawer
          filterValues={filterValues}
          visible={resultFilterDrawerOptions.visible}
          criteria={resultFilterDrawerOptions.criteria}
          showAllFilter={resultFilterDrawerOptions.showAllFilter}
          changeCriteria={changeCriteria}
          changeFilterValues={changeFilterValues}
          close={close}
          resetFilters={resetFilters}
        />
      )}
    </FiltersFormWrapper>
  );
};
