import { MarketingType, ObjectType, UsageType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { TFunction } from 'i18next';
import { number, string } from 'yup';

import { getMarketingTypeObjectTypeLabel } from '../../utils';
import { FLAT_IDS } from '../../utils/estateTypeIdUtils';
import { EstateSearchMainFormProps } from './EstateSearch.types';

export const getDefaultValues = (t: TFunction, perimeter?: number): EstateSearchMainFormProps => {
  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch and "objectType"
  const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
  return {
    zipCityEstateId: '',
    perimeter: perimeter ?? 10,
    marketingType: MarketingType.BUY,
    lastClickedMarketingTypeValue: MarketingType.BUY,
    objectType: !useImmobilienApi ? ObjectType.FLAT : undefined,
    estateTypeId: useImmobilienApi ? FLAT_IDS[0] * -1 : undefined,
    usageType: UsageType.RESIDENTIAL,
    marketingTypeObjectTypeTextfieldValue: getMarketingTypeObjectTypeLabel({
      t: t,
      marketingType: MarketingType.BUY,
      objectType: ObjectType.FLAT,
    }),
    maxPrice: undefined,
    maxMarketValue: undefined,
    maxRent: undefined,
    maxLease: undefined,
    minLivingSpace: undefined,
    minNumberRooms: undefined,
    minTotalSpace: undefined,
    minPropertySize: undefined,
  };
};

export const getBasicValidationSchema = (t: TFunction) => ({
  perimeter: number().integer().min(0).max(999999999).nullable(),
  zipCityEstateId: string().strict().min(1).required(t('estateSearch.errorRequiredWhere')),
});
