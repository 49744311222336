import { Box } from '@mui/material';
import { theme } from '@portals/core/src/themes/sde/main';
import React from 'react';

import { getStyles } from '../ImageContainer.styles';

export interface PartnerLogoProps {
  imgSrc: string;
}

export const PartnerLogo: React.FC<PartnerLogoProps> = ({ imgSrc }) => {
  const styles = getStyles(theme, imgSrc);

  return <Box sx={styles.partnerLogo} />;
};

PartnerLogo.displayName = 'PartnerLogo';
