import { Box } from '@mui/material';
import { Dialog } from '@portals/core/src/components/Dialog/Dialog';
import { Divider } from '@portals/core/src/components/Divider/Divider';
import { IconButton } from '@portals/core/src/components/IconButton/IconButton';
import { Pill } from '@portals/core/src/components/Pill/Pill';
import { theme } from '@portals/core/src/themes/sde/main';
import { ChevronLeft, ChevronRight, Close } from '@portals/icons';
import { EstateItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';

import { getStyles } from './MapEstateDialog.styles';
import { SimpleEstateDetails } from './SimpleEstateDetails/SimpleEstateDetails';

interface Props {
  onClose: () => void;
  estates?: Array<EstateItem>;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const MapEstateDialog: React.FunctionComponent<Props> = ({ onClose, estates }: Props) => {
  const styles = getStyles(theme);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(0);
  }, [estates]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const showPreviousEstate = useCallback(() => {
    setCurrentIndex((prevState) => {
      return prevState - 1;
    });
  }, []);

  const showNextEstate = useCallback(() => {
    setCurrentIndex((prevState) => {
      return prevState + 1;
    });
  }, []);

  if (estates.length === 0) {
    return null;
  }

  const estate = estates[currentIndex];
  const isFirstEstate = currentIndex === 0;
  const isLastEstate = currentIndex === estates.length - 1;

  return (
    <Dialog sx={styles.container} open={!!estates.length} onClose={handleClose}>
      <Box sx={styles.closeButton}>
        <IconButton onClick={handleClose} circular sx={styles.closeIconButton}>
          <Close />
        </IconButton>
      </Box>
      <SimpleEstateDetails
        title={estate.title}
        id={estate.id}
        price={estate.price}
        eyeCatcher={estate.eyeCatcher}
        images={estate.images}
        partnerLogo={estate.partnerLogo}
        mainFacts={estate.mainFacts}
        subtitle={estate.subtitle}
      />

      {estates.length > 1 && (
        <>
          <Divider sx={styles.divider} />
          <Box sx={styles.navContainer}>
            <IconButton
              circular
              color="default"
              component="button"
              sx={styles.navButton}
              onClick={showPreviousEstate}
              disabled={isFirstEstate}
              aria-label={t('previousObject')}
            >
              <ChevronLeft />
            </IconButton>
            <Pill
              sx={styles.navPill}
              size="responsive"
              label={t('objectPagination', { current: currentIndex + 1, max: estates.length })}
            />
            <IconButton
              circular
              color="default"
              component="button"
              sx={styles.navButton}
              onClick={showNextEstate}
              disabled={isLastEstate}
              aria-label={t('previousObject')}
            >
              <ChevronRight />
            </IconButton>
          </Box>
        </>
      )}
    </Dialog>
  );
};

MapEstateDialog.displayName = 'MapEstateDialog';
