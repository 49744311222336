import { Option as RadioInputButtonOption } from '@portals/forms/src/components/RadioInputButton/RadioInputButton';
import { Option as SelectOption } from '@portals/forms/src/components/SelectInput/SelectInput';
import {
  EstateEyecatcher,
  EstateMainFact,
  MainfactCategory,
} from '@portals/sip-client-data/src/general/ApiClientTypes';
import { isArray, omitBy } from 'lodash-es';
import { i18n } from 'next-i18next';

import { type EstateAdItem, type EstateListItemProps } from '../components';
import { AvailablenessState, type EstateAdProps, type Option as EstateAdOption } from '../config';

interface RadioOption extends RadioInputButtonOption {
  text: string;
}

export const today = new Date().toJSON().slice(0, 10);
export const notSpecifiedOptionValue = 'not_specified';

const numbersToSelectOption = (n: number): SelectOption => {
  return { id: `${n}`, text: `${n}` };
};

export const createNumberOptions = (n: number): SelectOption[] => {
  return Array.from({ length: n }, (_, i) => i + 1).map(numbersToSelectOption);
};

export const toSelectOptions = (option: EstateAdOption): SelectOption => {
  return { id: `${option.value}`, text: `${option.label}` };
};

export const toRadioOptions = (option: EstateAdOption, idx: number): RadioOption => {
  return { id: `${idx}`, text: option.value, label: option.label };
};

export const deleteNotNeededKeys = (estateAdProps: EstateAdProps): EstateAdProps => {
  delete estateAdProps.isAvailableByArrangement;
  delete estateAdProps.isConstructionYearUnknown;
  delete estateAdProps.hasParkingSpaces;
  delete estateAdProps.isEstatePriceIncluded;
  delete estateAdProps.energypassAvailableness;
  return estateAdProps;
};

export const removeEmptyOrNotSpecifiedProps = (estateAdProps: EstateAdProps): EstateAdProps => {
  return omitBy(estateAdProps, (value) => {
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      (isArray(value) && value.length === 0) ||
      value === notSpecifiedOptionValue
    );
  });
};

export const prepareEstateAdPropsForApi = (estateAdProps: EstateAdProps, shouldBePublished: boolean): EstateAdProps => {
  if (estateAdProps.isAvailableByArrangement === true) {
    estateAdProps.settingAvailableFrom = AvailablenessState.BY_ARRANGEMENT;
  }

  estateAdProps.publish = shouldBePublished;

  const reducedProps = removeEmptyOrNotSpecifiedProps(estateAdProps);
  return deleteNotNeededKeys(reducedProps);
};

export const priceFormatter = (price: string): string => {
  return Number(price).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
};

export const getMainfacts = (estateAdData: EstateAdItem): EstateMainFact[] => {
  i18n?.init();

  const mainfacts: Array<EstateMainFact> = [];

  if (estateAdData.spaceLiving) {
    mainfacts.push({
      category: MainfactCategory.AREA,
      label: `${estateAdData.spaceLiving} ${i18n?.t('forms.estateAd.squareMeter.unit')}`,
      value: '',
    });
  }

  if (estateAdData.spaceProperty) {
    mainfacts.push({
      category: MainfactCategory.AREA,
      label: `${estateAdData.spaceProperty} ${i18n?.t('forms.estateAd.squareMeter.unit')}`,
      value: '',
    });
  } else if (estateAdData.numRoom) {
    mainfacts.push({ category: MainfactCategory.ROOMS, label: `${estateAdData.numRoom}`, value: '' });
  }

  return mainfacts;
};

export const getEyecatcher = (
  estateAdData: EstateAdItem,
  t: (key: string, options?: Record<string, unknown>) => string
): EstateEyecatcher[] => {
  if (estateAdData.state === 'published') {
    return [
      {
        type: 'highlighted',
        label: t('userDashboard.estateAdList.status.liveSince', {
          publicationDate: new Date(estateAdData.modifiedDate).toLocaleDateString(),
        }),
      },
    ];
  }
  return [{ type: 'default', label: t(`userDashboard.estateAdList.status.${estateAdData.state}`) }];
};

export const mapFormDataToEstateTeaserProps = (estateAdData: EstateAdItem): EstateListItemProps => {
  return {
    id: estateAdData?.userEstateId,
    title: estateAdData?.textEstateHeading,
    subtitle: estateAdData?.geoCity,
    images: estateAdData?.images.map((img) => {
      return img.m;
    }),
    price: priceFormatter(estateAdData.priceBuy),
    eyeCatcher: [
      {
        type: 'default',
        label: 'Privatinserat',
      },
    ],
    mainFacts: getMainfacts(estateAdData),
    partnerLogo: null,
  };
};

export const storeEstateAdItem = (estateAd: EstateAdItem): void => {
  const storageKey = 'estate-ad-form-data';
  sessionStorage.setItem(storageKey, JSON.stringify(estateAd));
};

export const getStoredEstateAdItem = (): EstateAdItem => {
  const storageKey = 'estate-ad-form-data';
  return JSON.parse(sessionStorage.getItem(storageKey));
};
