import { Link as MUILink } from '@mui/material';
import { theme } from '@portals/core/src/themes/sde/main';
import React, { useCallback, useContext } from 'react';

import { AppConfig } from '../../../../config';
import { PageNavigationContext } from '../../../../context';
import { ActionFavoriteButton, EstateListItem, EstateListItemProps } from '../../..';
import { getStyles } from '../MapEstateDialog.styles';

export const SimpleEstateDetails: React.FC<EstateListItemProps> = ({
  id,
  title,
  subtitle,
  price,
  eyeCatcher,
  mainFacts,
  images,
  partnerLogo,
}) => {
  const styles = getStyles(theme);

  const { estateDetailsPath } = AppConfig;
  const url = `${estateDetailsPath}${encodeURIComponent(id)}.html`;
  const navigate = useContext(PageNavigationContext);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      navigate(url);
    },
    [navigate, url]
  );

  return (
    <MUILink href={url} sx={styles.muiLink} onClick={handleClick}>
      <EstateListItem
        id={id}
        title={title}
        subtitle={subtitle}
        images={images}
        price={price}
        eyeCatcher={eyeCatcher}
        mainFacts={mainFacts}
        partnerLogo={partnerLogo}
        bookmarkButton={<ActionFavoriteButton id={id} />}
        isLastItem={true}
        itemContainerSx={styles.itemContainer}
      ></EstateListItem>
    </MUILink>
  );
};

SimpleEstateDetails.displayName = 'SimpleEstateDetails';
