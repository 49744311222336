import { cleanSearchParams } from '@portals/sip-client-data/src/general/ApiClientMapping/ApiClientMapping';
import {
  EstateCountResponse,
  EstateSearchProps,
  ReturnType,
} from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { get, merge } from 'lodash-es';
import { ParsedUrlQuery } from 'querystring';

import { Method, performSipApiRequest } from '../api';
import { defaultPerimeter, EstateCollectionResponseType } from '../config';
import {
  convertSearchParamsToQuery,
  Logger,
  readFromCache,
  removeDoubleBracketsAndEmptyFieldsInBackend,
  removeNotWhitelistedParams,
  saveExpiringToCache,
} from '../utils';
import { performImmobilienApiRequest } from './common/performImmobilienApiRequest';

function getQueryParams(whitelistedApiParams, searchParams: EstateSearchProps) {
  return merge({}, convertSearchParamsToQuery(whitelistedApiParams), {
    return_data: ReturnType.TEASER_LIST,
    limit: searchParams.limit || 12,
    sort_by: searchParams.sortBy || 'distance_asc',
  });
}

export const getCleanSearchParams = (searchParams: EstateSearchProps): ParsedUrlQuery => {
  const whitelistedApiParams = removeNotWhitelistedParams(searchParams);
  const queryParams = getQueryParams(whitelistedApiParams, removeDoubleBracketsAndEmptyFieldsInBackend(searchParams));
  return cleanSearchParams(queryParams);
};

export const fetchEstateCollection = async (searchParams: EstateSearchProps): Promise<EstateCollectionResponseType> => {
  const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
  if (useImmobilienApi) {
    // TODO: Implemenent estate search call to Immobilien API
    return {
      totalItems: 0,
      page: 0,
      pageCount: 0,
      estates: [],
    };
  }
  searchParams.perimeter = searchParams.perimeter || defaultPerimeter;
  const cleanSearchParams = getCleanSearchParams(searchParams);
  const searchKey = `search_${JSON.stringify(cleanSearchParams)}`;
  let apiResponse = await readFromCache(searchKey);

  if (apiResponse === undefined || apiResponse === null) {
    apiResponse = await performSipApiRequest(Method.GET, 'estate', cleanSearchParams);
    saveExpiringToCache(searchKey, +process.env.CACHING_TIME_ESTATE_SEARCH, JSON.stringify(apiResponse));
  }
  const estates = get(apiResponse, '_embedded.estate', []);
  return {
    totalItems: get(apiResponse, 'total_items', 0),
    page: get(apiResponse, 'page', 0),
    pageCount: get(apiResponse, 'page_count', 0),
    estates: estates,
  };
};

export const fetchEstateDetails = async (id: string): Promise<any> => {
  const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
  if (useImmobilienApi) {
    const timeBefore = new Date().getTime();
    const apiResponse = await performImmobilienApiRequest(Method.GET, `estates/${id}`)
      .then((result) => result)
      .catch(() => null);
    const totalTime = new Date().getTime() - timeBefore;
    Logger.info(`estates/${id} ${totalTime}ms`);
    return apiResponse;
  }

  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION
  let apiResponse = await readFromCache(`expose_${id}`);
  const query = {
    return_data: ReturnType.REACT_FRONTEND,
    add_geo_polygon: 'true',
  };

  if (apiResponse === undefined || apiResponse === null) {
    const timeBefore = new Date().getTime();
    apiResponse = await performSipApiRequest(Method.GET, `estate/${id}`, cleanSearchParams(query));
    const totalTime = new Date().getTime() - timeBefore;
    Logger.info(`estate/${id} ${totalTime}ms`);

    saveExpiringToCache(`expose_${id}`, +process.env.CACHING_TIME_ESTATE_DETAIL, JSON.stringify(apiResponse)); // no await! await slows down the response
  }

  return {
    id: get(apiResponse, 'id', null),
    externId: get(apiResponse, 'externId', null),
    instituteCode: get(apiResponse, 'estate.blz', null),
    broker: {
      phone: get(apiResponse, 'estate.broker.phone', null),
      mobilePhone: get(apiResponse, 'estate.broker.mobilePhone', null),
      email: get(apiResponse, 'estate.broker.email', null),
      imageUrl: get(apiResponse, 'estate.broker.imageUrl', null),
      fullSalutation: get(apiResponse, 'estate.broker.fullSalutation', null),
      firstName: get(apiResponse, 'estate.broker.name', null),
      lastName: get(apiResponse, 'estate.broker.lastname', null),
    },
    contactPhoneRequired: get(apiResponse, 'estate.sip.contact_phone_required', false),
    contactAddressRequired: get(apiResponse, 'estate.sip.contact_address_required', false),
    isUserEstate: get(apiResponse, 'sip.is_user_estate', false),
    ...get(apiResponse, 'estate', {}),
  };
};

export const fetchEstateTotalCount = async (searchParams: EstateSearchProps): Promise<EstateCountResponse> => {
  const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
  if (useImmobilienApi) {
    // TODO: Implemenent estate search count call to Immobilien API
    throw new Error('Method not implemented.');
  } else {
    // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION
    const cleanApiParams = getCleanSearchParams(searchParams);
    const searchKey = `search_${JSON.stringify(cleanApiParams)}`;
    let apiResponse = await readFromCache(searchKey);

    if (apiResponse === undefined || apiResponse === null) {
      apiResponse = await performSipApiRequest(Method.GET, 'estate', cleanApiParams);
      saveExpiringToCache(searchKey, +process.env.CACHING_TIME_ESTATE_SEARCH, JSON.stringify(apiResponse));
    }
    return {
      totalItems: get(apiResponse, 'total_items', 0),
    };
  }
};
