import { MarketingType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useCallback, useContext } from 'react';

import { CatalogsContext } from '../../context';
import { getMarketingTypeEstateTypeIdLabel } from '../../utils';

export type formatterFunction = (marketingType: MarketingType, estateTypeId: number, defaultLabel?: string) => string;

export function useMarketingTypeEstateTypeIdFormatter(): formatterFunction {
  const { estateSubTypes: catalogsEstateSubTypes } = useContext(CatalogsContext);

  return useCallback((marketingType: MarketingType, estateTypeId: number, defaultLabel) => {
    const label = getMarketingTypeEstateTypeIdLabel({
      estateSubTypes: catalogsEstateSubTypes,
      marketingType: marketingType,
      estateTypeId: estateTypeId,
    });
    if (label === '') {
      return defaultLabel ? defaultLabel : '';
    }

    return label;
  }, []);
}
