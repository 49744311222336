import { Criteria, FilterConfigFormat } from '../../../../../config';
import type { FilterValuesProps } from '../../../../../types';
import { FilterConfigCriteria, getFilterConfig } from '../../../../../utils/estateTypeIdUtils';

const filterConfig: FilterConfigCriteria = getFilterConfig(FilterConfigFormat.Criteria) as FilterConfigCriteria;

export const getNumberOfActiveFilters = (filterValues: FilterValuesProps): number => {
  const singleFilterConfig = filterConfig?.find(
    (item) => item.marketingType === filterValues.marketingType && item.estateTypeId === filterValues.estateTypeId
  );
  const showPriceFilter = singleFilterConfig?.fields?.includes(Criteria.PRICE);
  const showRentFilter = singleFilterConfig?.fields?.includes(Criteria.RENT);
  const showLeaseFilter = singleFilterConfig?.fields?.includes(Criteria.LEASE);
  const showLivingSpaceFilter = singleFilterConfig?.fields?.includes(Criteria.LIVING_SPACE);
  const showPropertySizeFilter = singleFilterConfig?.fields?.includes(Criteria.PROPERTY_SIZE);
  const showTotalSpaceFilter = singleFilterConfig?.fields?.includes(Criteria.TOTAL_SPACE);
  const showRoomsFilter = singleFilterConfig?.fields?.includes(Criteria.ROOMS);

  let count = 0;

  if (filterValues.marketingType !== null && filterValues.estateTypeId !== null) {
    count++;
  }

  if (showPriceFilter && (filterValues.minPrice !== null || filterValues.maxPrice !== null)) {
    count++;
  }

  if (showRentFilter && (filterValues.minRent !== null || filterValues.maxRent !== null)) {
    count++;
  }

  if (showLeaseFilter && (filterValues.minLease !== null || filterValues.maxLease !== null)) {
    count++;
  }

  if (showLivingSpaceFilter && (filterValues.minLivingSpace !== null || filterValues.maxLivingSpace !== null)) {
    count++;
  }

  if (showPropertySizeFilter && (filterValues.minPropertySize !== null || filterValues.maxPropertySize !== null)) {
    count++;
  }
  if (showPropertySizeFilter && filterValues?.conditionDevelopment?.trim() !== '') {
    count++;
  }

  if (showTotalSpaceFilter && (filterValues.minTotalSpace !== null || filterValues.maxTotalSpace !== null)) {
    count++;
  }

  if (showRoomsFilter && (filterValues.minNumberRooms !== null || filterValues.maxNumberRooms !== null)) {
    count++;
  }

  return count;
};
