import { SxProps } from '@mui/material';
import { Pill } from '@portals/core/src/components/Pill/Pill';
import React, { MouseEventHandler } from 'react';

import { styles } from './HorizontalSwipeBar.styles';

interface FilterPillProps {
  className?: string;
  avatar?: React.ReactElement;
  label?: string;
  sx?: SxProps;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onFocus?: () => void;
}

export const FilterPill: React.FC<FilterPillProps> = ({ avatar, label, sx = {}, onClick, onFocus }) => {
  const combinedSx: SxProps = { ...(styles.button as SxProps), ...sx };

  return (
    <Pill
      sx={combinedSx}
      avatar={avatar}
      label={label}
      onClick={onClick}
      onFocus={onFocus}
      className="estate-filter-pill"
    />
  );
};

FilterPill.displayName = 'FilterPill';
