import { ParsedUrlQuery } from 'querystring';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalstorageState } from 'rooks';

import { loginConfig, LoginContext } from '../../context';
import { useSearchIsSaved } from '../../hooks';
import {
  getBookmarksByURL,
  getUserApiLoginState,
  isLocalStorageBookmarksEnabled,
  SipUserApiLoginState,
  STORAGE_KEY_BOOKMARKS,
} from '../../utils';

type UserData = {
  name: string;
  email: string;
  userId: string;
};

export type AfterRegistrationAction = {
  hash: string;
  type: string;
  data: any;
};

export type LoginState = {
  isLoggedIn: boolean;
  userData?: UserData;
  showIframe: boolean;
  setIframeVisibility: (value: boolean) => void;
  checkForLoginStateUpdates: () => Promise<boolean> | void;
  onLogin: () => void;
  doLogin: (
    callback: (estateId?: string) => void,
    afterRegisterParams?: ParsedUrlQuery,
    afterRegistrationAction?: AfterRegistrationAction
  ) => void;
  afterRegisterParams?: ParsedUrlQuery;
  resetAfterRegistrationState: () => void;
  bookmarks: Array<string>;
  loadBookmarks: () => Promise<void>;
  isRequestingLoginState: boolean;
};

export const UserContext = React.createContext<LoginState>({
  isLoggedIn: false,
  showIframe: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIframeVisibility: function () {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  checkForLoginStateUpdates: function () {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLogin: function () {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  doLogin: function () {},
  resetAfterRegistrationState: () => null,
  bookmarks: [],
  loadBookmarks: () => null,
  isRequestingLoginState: true,
});

let loginCallBack = { resolve: () => null };

export let afterRegistrationAction: AfterRegistrationAction | null;

const getParsedBookmarks = (bookmarks) => {
  return typeof bookmarks === 'string' && bookmarks.length > 0 ? JSON.parse(bookmarks) : bookmarks;
};

export const LoginHandler = (props): React.ReactElement => {
  const [showLoginIframe, setIframeVisibility] = useState(false);
  const [afterRegisterParams, setAfterRegisterParams] = useState(null);
  const [loginState, setLoginState] = useState<SipUserApiLoginState>({ authenticated: null });
  const { loadSavedSearches } = useSearchIsSaved();
  const [isRequestingLoginState, setIsRequestingLoginState] = useState(true);

  // user api bookmarks also use useLocalstorage(). Prevent interference
  const [bookmarks, setBookmarks] = isLocalStorageBookmarksEnabled()
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useState('')
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useLocalstorageState(STORAGE_KEY_BOOKMARKS, []);

  const loadBookmarks = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setBookmarks(await getBookmarksByURL(loginConfig.userApiUrl));
  }, [setBookmarks]);

  const requestLoginState = useCallback(async (): Promise<boolean> => {
    const userApiResponse = await getUserApiLoginState(loginConfig.userApiUrl);
    setLoginState(userApiResponse);
    if (userApiResponse.authenticated) {
      // no need to load user api book marks
      if (isLocalStorageBookmarksEnabled()) {
        await loadBookmarks();
      }
      loadSavedSearches();
      return true;
    }

    return false;
  }, [loadBookmarks]);

  useEffect(() => {
    (async () => {
      setIsRequestingLoginState(true);
      await requestLoginState();
      setIsRequestingLoginState(false);
    })();
  }, [requestLoginState]);

  const doLogin = useCallback((callbackFn, afterRegisterParams, newAfterRegistrationAction) => {
    loginCallBack = { resolve: callbackFn };
    setAfterRegisterParams(afterRegisterParams);
    afterRegistrationAction = newAfterRegistrationAction;
    setIframeVisibility(true);
  }, []);

  const onLogin = () => {
    loginCallBack.resolve();
  };

  const loginStateVal = useMemo(() => {
    const userData = loginState.authenticated
      ? {
          name: loginState.loginInfo?.name,
          email: loginState.loginInfo?.email,
          userId: loginState.loginInfo?.userId,
        }
      : null;

    return {
      isLoggedIn: loginState.authenticated === true,
      showIframe: showLoginIframe,
      userData,
      setIframeVisibility,
      checkForLoginStateUpdates: requestLoginState,
      onLogin,
      doLogin,
      afterRegisterParams,
      resetAfterRegistrationState: () => {
        setAfterRegisterParams(null);
        afterRegistrationAction = null;
      },
      bookmarks: getParsedBookmarks(bookmarks),
      loadBookmarks,
      isRequestingLoginState,
    };
  }, [
    loginState.authenticated,
    showLoginIframe,
    requestLoginState,
    afterRegisterParams,
    bookmarks,
    loadBookmarks,
    isRequestingLoginState,
    doLogin,
  ]);
  return (
    <LoginContext.Provider value={loginConfig}>
      <UserContext.Provider value={loginStateVal}>{props.children}</UserContext.Provider>
    </LoginContext.Provider>
  );
};

LoginHandler.displayName = 'LoginHandler';
