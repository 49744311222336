import { SxProps, Theme } from '@mui/material/styles';

export const getStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  itemContainer: {
    py: { xs: theme.spacing(6), lg: theme.spacing(9) },
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: { xs: theme.spacing(5), sm: theme.spacing(8), lg: theme.spacing(9) },
  },
  imageContainer: {
    maxWidth: { xs: '100%', sm: '259px', lg: '389px' },
    width: 1,
    height: 'auto',
    aspectRatio: '3/2',
  },
  infoWrapper: { display: 'flex', flexDirection: 'column', width: '100%' },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: { xs: theme.spacing(5), sm: theme.spacing(4), md: theme.spacing(5), lg: theme.spacing(6) },
    pb: { xs: theme.spacing(5), sm: theme.spacing(4), md: theme.spacing(5), lg: theme.spacing(6) },
  },
  infoContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: { xs: theme.spacing(5), sm: theme.spacing(4), md: theme.spacing(5), lg: theme.spacing(4) },
  },
  title: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  eyeCatcher: {
    display: 'flex',
    gap: { xs: theme.spacing(2), lg: theme.spacing(4) },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flex: '1 0 0',
    alignSelf: 'stretch',
    gap: { xs: theme.spacing(5), lg: theme.spacing(6) },
  },
  mainFacts: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: { xs: theme.spacing(5), lg: theme.spacing(6) },
    pb: { lg: theme.spacing(1) },
  },
});
