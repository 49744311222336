import { Box, FormHelperText, Grid, useTheme } from '@mui/material';
import { Option, SelectInput } from '@portals/forms/src/components/SelectInput/SelectInput';
import { TextInput } from '@portals/forms/src/components/TextInput/TextInput';
import { MarketingType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useFormikContext } from 'formik';
import i18next from 'i18next';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { EstateFilterTypes, FilterConfigFormat, if6CssPrefix } from '../../../../config';
import { CatalogsContext } from '../../../../context';
import { FilterConfigEstateTypeFilter, getFilterConfig } from '../../../../utils/estateTypeIdUtils';

interface Props {
  marketingType: MarketingType;
  estateTypeId: number;
  setValidationSchema: (validationSchema: any) => void;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateSearchFilterView = ({ marketingType, estateTypeId, setValidationSchema }: Props) => {
  const { loading: catalogsLoading, roomsFrom: catalogsRoomsFrom } = useContext(CatalogsContext);

  const theme = useTheme();

  const formatRoomsFromCatalog = useCallback(
    (defaultValues: Option[]): Option[] => {
      if (catalogsLoading || !catalogsRoomsFrom || catalogsRoomsFrom.length === 0) {
        return defaultValues;
      }
      return [
        {
          id: '',
          text: t('estateSearch.any'),
        },
        ...catalogsRoomsFrom
          .filter((item) => item.id > -1)
          .map((item) => ({
            id: `${item.id}`,
            text: item.code,
          })),
      ];
    },
    [catalogsLoading, catalogsRoomsFrom]
  );

  const filterConfig: FilterConfigEstateTypeFilter = getFilterConfig(
    FilterConfigFormat.EstateFilterType
  ) as FilterConfigEstateTypeFilter;

  const singleFilterConfig = useMemo(
    () => filterConfig?.find((item) => item.marketingType === marketingType && item.estateTypeId === estateTypeId),
    [filterConfig, marketingType, estateTypeId]
  );

  useEffect(() => {
    setValidationSchema(singleFilterConfig?.validationSchema);
  }, [singleFilterConfig]);

  const { values, errors, setFieldTouched, setFieldValue } = useFormikContext();

  const checkIfTypeIsOther = (value) => {
    return value === Number.MAX_VALUE ? null : value;
  };

  const setFormikValue = (type, value) => {
    setFieldTouched(type, true);
    setFieldValue(type, checkIfTypeIsOther(value), true);
  };

  const getFieldErrorMessage = (message) => (
    <Box pl={theme.spacing(4)}>
      <FormHelperText error={true}>{message}</FormHelperText>
    </Box>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {singleFilterConfig?.fields?.map((field) => {
        const initialValue = values[field.type];
        switch (field.type) {
          case EstateFilterTypes.MAX_PRICE:
          case EstateFilterTypes.MAX_RENT:
          case EstateFilterTypes.MAX_MARKET_VALUE:
          case EstateFilterTypes.MAX_LEASE:
            return (
              <Grid item xs key={field.type}>
                <TextInput
                  data-testid="priceSearch"
                  name="priceSearch"
                  label={`${t('priceSearchPlaceholder')} (€)`}
                  fullWidth
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={initialValue ? `${initialValue}` : ''}
                  onChange={(event) => {
                    setFormikValue(field.type, event.target.value);
                  }}
                  className="estate-input"
                />
                {getFieldErrorMessage(errors[field.type])}
              </Grid>
            );
          case EstateFilterTypes.MIN_LIVING_SPACE:
            return (
              <Grid item xs key={field.type}>
                <TextInput
                  data-testid="livingSpaceSearch"
                  name="livingSpaceSearch"
                  label={`${t('livingSpaceSearchPlaceholder')} (m²)`}
                  fullWidth
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={initialValue ? `${initialValue}` : ''}
                  onChange={(event) => {
                    setFormikValue(field.type, event.target.value);
                  }}
                  className="estate-input"
                />
                {getFieldErrorMessage(errors[field.type])}
              </Grid>
            );
          case EstateFilterTypes.MIN_NUMBER_ROOMS:
            return (
              <Grid item xs key={field.type}>
                <div className="estate-select">
                  <SelectInput
                    data-testid="roomSearch"
                    name="roomSearch"
                    label={t('roomSearchPlaceholder')}
                    fullWidth
                    selectOptions={formatRoomsFromCatalog([])}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    value={initialValue ? `${initialValue}` : ''}
                    onChange={(event) => {
                      setFormikValue(field.type, event.target.value ? +event.target.value : null);
                    }}
                    MenuProps={{
                      classes: {
                        paper: `${if6CssPrefix} estate-select-list`,
                      },
                    }}
                  />
                </div>
                {getFieldErrorMessage(errors[field.type])}
              </Grid>
            );
          case EstateFilterTypes.MIN_PROPERTY_SIZE:
            return (
              <Grid item xs key={field.type}>
                <TextInput
                  data-testid="propertySizeSearch"
                  name="propertySizeSearch"
                  label={`${t('propertySizeSearchPlaceholder')} (m²)`}
                  fullWidth
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={initialValue ? `${initialValue}` : ''}
                  onChange={(event) => {
                    setFormikValue(field.type, event.target.value);
                  }}
                  className="estate-input"
                />
                {getFieldErrorMessage(errors[field.type])}
              </Grid>
            );
          case EstateFilterTypes.MIN_TOTAL_SPACE:
            return (
              <Grid item xs key={field.type}>
                <TextInput
                  data-testid="totalSpaceSearch"
                  name="totalSpaceSearch"
                  label={`${t('totalSpaceSearchPlaceholder')} (m²)`}
                  fullWidth
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={initialValue ? `${initialValue}` : ''}
                  onChange={(event) => {
                    setFormikValue(field.type, event.target.value);
                  }}
                  className="estate-input"
                />
                {getFieldErrorMessage(errors[field.type])}
              </Grid>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

EstateSearchFilterView.displayName = 'EstateSearchFilterView';
