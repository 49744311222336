import { Box, useTheme } from '@mui/material';
import React, { SetStateAction } from 'react';

import { CarouselIcon } from '../../MaterialUiCarousel/CarouselIcon';
import MaterialUiCarousel from '../../MaterialUiCarousel/MaterialUiCarousel';
import { getStyles } from '../GalleryModal/GalleryModal.styles';

export interface Props {
  galleryCarouselItems: Array<React.ReactNode>;
  onActiveSlide: (state: SetStateAction<number>) => void;
  currentImagePosition: number;
}

export const GalleryCarousel = ({
  galleryCarouselItems,
  onActiveSlide,
  currentImagePosition,
}: Props): React.ReactElement => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const showNavButtons = galleryCarouselItems.length > 1;

  return (
    <MaterialUiCarousel
      index={currentImagePosition}
      onChange={onActiveSlide}
      fullHeightHover={false}
      navButtonsAlwaysVisible
      NavButton={(props) => {
        return (
          showNavButtons && (
            <Box sx={{ mx: { xs: theme.spacing(5), lg: theme.spacing(8) } }}>
              <CarouselIcon
                {...props}
                sx={styles.navButton}
                style={{
                  backgroundColor: theme.palette.background.default,
                }}
              />
            </Box>
          )
        );
      }}
      sx={{
        width: '100%',
      }}
    >
      {galleryCarouselItems}
    </MaterialUiCarousel>
  );
};

GalleryCarousel.displayName = 'GalleryCarousel';
