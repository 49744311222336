import { Box, FormHelperText, Grid, NoSsr, useTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Button } from '@portals/core/src/components/Button/Button';
import { TextField } from '@portals/core/src/components/TextField/TextField';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { useGeolocation } from '@portals/core/src/hooks/useGeolocation';
import { Close, Home, Location } from '@portals/icons';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { Address, TravelTimeQueryParams } from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import { map, trim } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocalstorageState } from 'rooks';

import { SpeedProfile } from '../../../../config';
import { usePreventSafariAutozoom } from '../../../../hooks';
import { Bicycle, Car, Foot, Perimeter } from '../../../../icons';
import { Logger } from '../../../../utils';
import { getStyles } from './TravelTime.style';
import { TravelTimeBadge } from './TravelTimeBadge/TravelTimeBadge';

interface Props {
  address: Address;
  lat: number;
  lng: number;
}

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const TravelTime = ({ address, lat, lng }: Props): React.ReactElement => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { fetch } = useGeolocation();
  const [lastAddress, setLastAddress] = useLocalstorageState('travelTime_lastAddress', '');
  const [currentAddress, setCurrentAddress] = useState({
    lat: null,
    lng: null,
    text: '',
  });
  const [distanceItems, setDistanceItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const preventSafariAutozoom = usePreventSafariAutozoom();

  useEffect(() => {
    setCurrentAddress({
      lat: null,
      lng: null,
      text: lastAddress,
    });
  }, [lastAddress]);

  const generateAddressLabel = () => {
    if (address.street) {
      return `${address.street} ${address.streetNumber} ${address.zip} ${address.city}`;
    }

    return `${address.zip} ${address.city}`;
  };

  const clear = useCallback(() => {
    setCurrentAddress({
      lat: null,
      lng: null,
      text: '',
    });
    setDistanceItems([]);
  }, []);

  const getCoordinates = useCallback(() => {
    fetch()
      .then(({ latitude, longitude }) => {
        setCurrentAddress({
          lat: latitude,
          lng: longitude,
          text: t('travel.yourCurrentLocation'),
        });
      })
      .catch(() => {
        enqueueSnackbar(t('travel.enableLocationNotification'));
        clear();
      });
  }, [enqueueSnackbar, fetch, t, clear]);

  const calculateTravelTime = useCallback(async () => {
    setError(false);
    setLoading(true);
    setDistanceItems([]);

    if (currentAddress.lat == null && currentAddress.lng == null) {
      setLastAddress(currentAddress.text);
    }
    const distanceQuery: TravelTimeQueryParams = {
      startLat: lat,
      startLng: lng,
      endLat: currentAddress.lat,
      endLng: currentAddress.lng,
      text: trim(currentAddress.text),
    };

    ApiClientProvider.getApiClient()
      .getTravelTimes(distanceQuery)
      .then(({ data }) => {
        setDistanceItems(data);
      })
      .catch((e) => {
        setError(true);
        Logger.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentAddress.lat, currentAddress.lng, currentAddress.text, lat, lng, setLastAddress]);

  const changeAddress = useCallback((e) => {
    setCurrentAddress({
      lat: null,
      lng: null,
      text: e.target.value,
    });
  }, []);

  const getIcon = (profile) => {
    switch (profile) {
      case SpeedProfile.FAST:
      case SpeedProfile.SLOW:
        return Car;
      case SpeedProfile.BICYCLE:
        return Bicycle;
      case SpeedProfile.PEDESTRIAN:
        return Foot;
      default:
        return undefined;
    }
  };

  const getLabel = (seconds) => {
    if (seconds === 0) {
      return t('travel.minutes', { minutes: 1 });
    }

    const minutes = Math.ceil(seconds / 60);
    if (minutes < 60) {
      return t('travel.minutes', { minutes });
    }

    const minutesWithHours = {
      hours: (minutes - (minutes % 60)) / 60,
      minutes: minutes % 60,
    };
    return t('travel.hoursAndMinutes', minutesWithHours);
  };

  const renderTravelTimeBadge = ({ profile, seconds }) => {
    return (
      <Box sx={styles.travelTimeBadgeContainer}>
        <TravelTimeBadge key={`${profile}-badge`} Icon={getIcon(profile)} label={getLabel(seconds)} />
      </Box>
    );
  };

  const getInputAdornment = () => {
    if (!trim(currentAddress.text)) {
      return (
        <InputAdornment position="end">
          <Perimeter sx={styles.inputAdornmentIcon} onClick={getCoordinates} />
        </InputAdornment>
      );
    }
    return (
      <InputAdornment position="end">
        <Close sx={styles.inputAdornmentClearIcon} onClick={clear} />
      </InputAdornment>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">{t('travel.headline')}</Typography>
        <Typography variant="body2" sx={styles.travelBodyText}>
          {t('travel.body')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={styles.travelContainer}>
          <Box sx={styles.travelIcons}>
            <Home />
            <Box sx={styles.travelLine} />
            <Location />
          </Box>
          <Box sx={styles.travelInputContainer}>
            <Box sx={styles.travelAddressLabelContainer}>
              <Typography variant="button">{generateAddressLabel()}</Typography>
            </Box>
            <Box>
              <NoSsr>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={currentAddress.text}
                  onChange={changeAddress}
                  placeholder={t('travel.placeholder')}
                  slotProps={{
                    input: {
                      endAdornment: getInputAdornment(),
                    },
                  }}
                  sx={styles.travelInput}
                  {...preventSafariAutozoom}
                />
              </NoSsr>
            </Box>
          </Box>
        </Box>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Box sx={styles.errorOuterContainer}>
            <Box sx={styles.errorInnerContainer}>
              <FormHelperText error={true}>{t('travel.error')}</FormHelperText>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sx={styles.submitButtonContainer}>
        <Button
          color="secondary"
          onClick={calculateTravelTime}
          fullWidth
          disabled={trim(currentAddress.text) === ''}
          loading={loading}
        >
          {t('travel.button')}
        </Button>
      </Grid>
      <Grid item xs={12} sx={styles.travelTimeBadgesOuterContainer}>
        <Box sx={styles.travelTimeBadgesInnerContainer}>
          {map(distanceItems, (distanceItem) => {
            return renderTravelTimeBadge(distanceItem);
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

TravelTime.displayName = 'TravelTime';
