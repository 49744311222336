import { FinalGridContainer, FinalGridItem } from '@portals/core/src/components/FinalGrid/FinalGrid';
import { LoadingDots } from '@portals/core/src/components/LoadingDots/LoadingDots';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import {
  EstateListResponse,
  EstateObjectTypeOptions,
  EstateSearchProps,
} from '@portals/sip-client-data/src/general/ApiClientTypes';
import i18next from 'i18next';
import { merge } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';

import { UseNoResultActionUtilsType } from '../../types/estateList';
import { estateObjectTypesToQueryString, Logger, removeBrackets, removeEmptyParams } from '../../utils';
import { EstateListViewRenderProps } from '../EstateListView/EstateListView.types';
import { ListItems } from './ListItems/ListItems';
import { NoResultNotification } from './NoResultNotification/NoResultNotification';

export type EstateCardViewProps = {
  estateListHeadline?: string;
  estateListHeadlineVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
  estateSearchParameters?: EstateSearchProps;
  objectTypes?: EstateObjectTypeOptions;
  estatesData?: EstateListResponse;
  useNoResultActionUtils?: UseNoResultActionUtilsType;
} & EstateListViewRenderProps;

const CONTAINER_COLUMN_GAP = { xs: 0, sm: 6, md: 6, lg: 9 };
const CONTAINER_ROW_GAP = { xs: 8, sm: 8, md: 8, lg: 10 };
const DEFAULT_ESTATES_LIMIT = 9;

let didFetchEstates = false;

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateCardView: React.FunctionComponent<EstateCardViewProps> = ({
  estateListHeadline = undefined,
  estateListHeadlineVariant = 'h2',
  estateSearchParameters,
  objectTypes,
  estatesData,
  renderEstateLink,
  renderBookmarkButton,
  useNoResultActionUtils,
}: EstateCardViewProps) => {
  const currentPage = useRef(estatesData?.page ?? 0);
  const [showLoader, setShowLoader] = useState(!estatesData);
  const [estates, setEstates] = useState(estatesData?.estates ?? []);
  const [estatesCount, setEstatesCount] = useState(estatesData?.totalItems ?? 0);
  const [isFetching, setIsFetching] = useState(!estatesData);

  useEffect(() => {
    const fetchEstates = async () => {
      currentPage.current = 1;

      const estateTypes = estateObjectTypesToQueryString(objectTypes);
      const queryParameters = removeBrackets(estateSearchParameters);

      const query = removeEmptyParams(
        merge({}, queryParameters, {
          page: currentPage.current,
          objectType: estateTypes,
          limit: estateSearchParameters?.limit ?? DEFAULT_ESTATES_LIMIT,
        })
      );

      try {
        const estatesResponse = await ApiClientProvider.getApiClient().getEstates(query);
        const totalItems = estatesResponse.totalItems;
        const estates = estatesResponse.estates;

        setEstatesCount(totalItems);
        setEstates(estates);
      } catch (e) {
        Logger.error(e);
      } finally {
        setShowLoader(false);
        setIsFetching(false);
      }
    };

    if (!didFetchEstates && !estatesData) {
      didFetchEstates = true;
      fetchEstates();
    }
  }, [estateSearchParameters, objectTypes, estatesData]);

  return (
    <FinalGridContainer data-testid="estate-list" sx={{ columnGap: CONTAINER_COLUMN_GAP, rowGap: CONTAINER_ROW_GAP }}>
      {isFetching && (
        <FinalGridItem md={12} lg={12} textAlign="center" data-testid="loading-feedback">
          <LoadingDots />
        </FinalGridItem>
      )}

      {!isFetching && estatesCount > 0 && (
        <>
          {estateListHeadline && (
            <FinalGridItem md={12} lg={12}>
              <Typography
                component={estateListHeadlineVariant}
                variant={estateListHeadlineVariant !== 'div' ? estateListHeadlineVariant : 'body2'}
                innerPadding="horizontal"
              >
                {estateListHeadline}
              </Typography>
            </FinalGridItem>
          )}
          <ListItems
            totalItems={estatesCount}
            estates={estates}
            showLoader={showLoader}
            currentPage={currentPage}
            searchParams={estateSearchParameters}
            objectTypes={objectTypes}
            setEstates={setEstates}
            setShowLoader={setShowLoader}
            renderEstateLink={renderEstateLink}
            renderBookmarkButton={renderBookmarkButton}
          />
        </>
      )}

      {!isFetching && estatesCount === 0 && (
        <FinalGridItem md={12} lg={12} data-testid="no-results-container">
          <NoResultNotification
            mainText={t('noResult.estateList.mainText')}
            subText={t('noResult.estateList.subText')}
            buttonLabel={t('noResult.estateList.buttonLabel')}
            buttonAction={useNoResultActionUtils?.handleAction}
            isButtonClicked={useNoResultActionUtils?.isProcessing}
          />
        </FinalGridItem>
      )}
    </FinalGridContainer>
  );
};

EstateCardView.displayName = 'EstateCardView';
