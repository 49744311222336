import type { SxProps } from '@mui/system';

export const styles: Record<string, SxProps> = {
  listContainer: {
    mt: { xs: '0px !important' },
    width: '100%',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridContainerEstateList: {
    mt: { xs: 0 },
    marginBottom: 0,
    px: { xs: 0 },
    width: '100%',
  },
  gridItemEstateList: {
    mt: 0,
    width: { xs: '100%', lg: '800px' },
    mb: 8,
    gridColumnStart: {
      text: 1,
      lg: 1,
    },
    gridColumnEnd: {
      text: 9,
      lg: 9,
    },
  },
  saveSearchButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    mx: 6,
  },
  saveSearchButtonLabel: {
    pointerEvents: 'auto',
    pr: 2,
  },
  loadingControlContainer: {
    width: 1,
    display: 'flex',
    justifyContent: 'center',
    py: 5,
  },
};
